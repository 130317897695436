window.basename = function(path, suffix) {
  var b = path.replace(/^.*[/\\]/g, '');
  if (typeof(suffix) === 'string' && b.substr(b.length - suffix.length) === suffix) {
    b = b.substr(0, b.length - suffix.length);
  }
  return b;
}

window.getFilenameExtension = function(str) {
  if (str) {
    var array = str.split('?');
    str = array[0];
    array = str.split('#');
    str = array[0];
    var basename = window.basename(str);
    if (basename.indexOf('.') === -1) {
      return '';
    }
    if (str.indexOf(' ') !== -1) {
      return '';
    }
    var temp = basename.split('.');
    var ext = temp[temp.length - 1];
    if (ext === parseFloat(ext)) {
      return '';
    }
    if (ext.charAt(ext.length - 1) === '#') {
      ext = ext.substr(0, ext.length - 1);
    }
    return ext.toLowerCase();
  } else {
    return '';
  }
}

window.noteNames = { "C":0, "C#":1, "Db":1, "D":2, "D#":3, "Eb":3, "E":4, "F":5, "F#":6, "Gb":6, "G":7, "G#":8, "Ab":8, "A":9, "A#":10, "Bb":10, "B":11 };

window.isValidNoteName = function(noteName) {
  if (noteName.substring) {
    var octavePortionLength;
    if (noteName.length === 4) {
      octavePortionLength = 2;
    } else {
      octavePortionLength = 1;
    }
    var name = noteName.substring(0, noteName.length - octavePortionLength);
    return window.noteNames[name] !== null && window.noteNames[name] !== undefined;
  } else {
    return false;
  }
}

window.getSheetsIdFromUrl = function(url) {
  var result = url.match(/[-\w]{25,}/);
  if (result == null) {
  	return null;
  } else {
  	return result[0];
  }
}

window.getLayoutComponent = function(layoutString, component) {
  var temp = layoutString.split(' ');
  var value;
  switch (component) {
    case 'left':
    value = parseFloat(temp[0]);
    break;
    case 'top':
    value = parseFloat(temp[1]);
    break;
    case 'width':
    value = parseFloat(temp[2]);
    break;
    case 'height':
    value = parseFloat(temp[3]);
    break;
    default:
    break;
  }
  if (isNaN(value)) {
    if (component === 'width' || component === 'height') {
      value = 1;
    } else {
      value = 0;
    }
  }
  return value;
}

window.getMarginComponent = function(marginString, component) {
  var temp = marginString.split(' ');
  if (temp.length === 1) {
    temp.push(temp[0]);
    temp.push(temp[0]);
    temp.push(temp[0]);
  } else if (temp.length === 2) {
    temp.splice(1, null, temp[0]);
    temp.push(temp[2]);
  }
  var value;
  switch (component) {
    case 'top':
    value = parseFloat(temp[0]);
    break;
    case 'right':
    value = parseFloat(temp[1]);
    break;
    case 'bottom':
    value = parseFloat(temp[2]);
    break;
    case 'left':
    value = parseFloat(temp[3]);
    break;
    default:
    break;
  }
  return value;
}

window.radToDeg = function(radians) {
  return (radians * (180 / Math.PI)) % 360;
}

window.degToRad = function(degrees) {
  return ((degrees % 360) / 360.0) * (Math.PI * 2);
}

window.convertToSlug = function(str) {
  return str.toLowerCase().replace(/[^\w ]+/g,'').replace(/ +/g,'-');
}

export class Point {
  constructor(x, y) {
    this.x = x;
    this.y = y;
  }
}

export class Rect {
  constructor(x, y, width, height) {
    this.x = parseFloat(x);
    this.y = parseFloat(y);
    this.width = width;
    this.height = height;
    this.xMin = this.x;
    this.yMin = this.y;
    this.xMax = this.x + this.width;
    this.yMax = this.y + this.height;
  }
  contains(point) {
    return (point.x >= this.xMin && point.x <= this.xMax && point.y >= this.yMin && point.y <= this.yMax);
  }
  center() {
    return new Point(this.x + (this.width * .5), this.y + (this.height * .5));
  }
}

export class PanelSubRect {
  constructor(panel, rect) {
    this.panel = panel;
    this.rect = rect;
  }
}
