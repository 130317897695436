import React from 'react';

class PanelMatte extends React.Component {
  constructor(props) {
    super(props);
    this.transition = .5;
    this.matte = React.createRef();
  }

  componentDidMount() {
    this.setTransition(.5);
  }

  shouldComponentUpdate() {
    return false;
  }

  setTransition(secs) {
    this.transition = secs;
    this.matte.current.style.transition = `background-color ${secs}s`;
  }

  setColor(color) {
    this.matte.current.style.backgroundColor = color;
  }

  handleStep(step) {
    if (step) {
      switch (step.command) {

        case 'setFadeDuration':
        this.setTransition(parseFloat(step.content));
        break;

        case 'fadeTo':
        this.setColor(step.content);
        break;

        default:
        break;
      }
    }
  }

  render () {
    return (
      <div className="panel-matte disable-interaction" ref={this.matte}/>
    )
  }
}

export default PanelMatte;
