import React from 'react';
import * as Tone from 'tone';
import TWEEN from '@tweenjs/tween.js';

class AudioFeature extends React.Component {
  constructor(props) {
    super(props);
    this.element = React.createRef();
    this.stateAudio = React.createRef();
    this.currentStateAudio = null;
    this.playing = false;
    this.isMuted = false;
    this.currentActionAudio = null;
    this.players = {};
    this.isFading = false;
    this.animateFade = this.animateFade.bind(this);
  }

  shouldComponentUpdate() {
    return false;
  }

  handleStoryUpdate() {
    this.forceUpdate();
  }

  applyState(state) {
    this.currentState = state;
    for (let property in state) {
      switch (property) {

        case 'media':
        /*let media = this.props.stepwise.score.getMedia(state[property]);
        this.currentVideo = media;
        this.playerURL = media.source;
        this.forceUpdate();*/
        //this.player.current.forceUpdate();
        this.setAudio(this.props.stepwise.score.getMedia(state[property]));
        break;

        case 'volume':
        if (this.stateAudio.current) {
          if (state.volume !== this.stateAudio.current.volume) {
            if (this.volumeTween) this.volumeTween.stop();
            if (state.transitionDuration > 0) {
              this.volumeTween = new TWEEN.Tween(this.stateAudio.current)
                .to({volume:state.volume}, state.transitionDuration * 1000)
                .onComplete(() => {
                  this.isFading = false;
                })
                .start();
              this.isFading = true;
              requestAnimationFrame(this.animateFade);
            } else {
              this.stateAudio.current.volume = state.volume;
            }
          }
        }
        break;

        default:
        //this.video.current.style[property] = state[property];
        break;
      }
    }
    /*if (this.stateAudio) {
      this.stateAudio.current.volume = state.volume;
    }*/
    this.setTransition(state.transitionDuration);
  }

  animateFade(time) {
    if (this.isFading) requestAnimationFrame(this.animateFade);
    TWEEN.update(time);
  }

  preloadAudioForSequence(sequence) {
    sequence.steps.forEach(step => {
      let media;
      /*step.states.forEach(state => {
        if (state.type === 'audio') {
          media = this.state.stepwise.score.getMedia(state.media);
          if (media) {
            this.addAudioPlayer(media);
          }
        }
      });*/
      step.actions.forEach(action => {
        if (action.targetCharacter === this.props.feature.parentCharacter && action.command === 'play-audio') {
          media = this.props.stepwise.score.getMedia(action.content);
          if (media) {
            this.addAudioPlayer(media);
          }
        }
      });
    });
  }

  addAudioPlayer(media, playImmediately = false, volume = 1) {
    if (!this.players[media.id]) {
      //console.log('adding audio player: ' + media.source);
      this.players[media.id] = new Tone.Player(media.source).toDestination();
      this.players[media.id].volume.value = Tone.gainToDb(volume);
      if (playImmediately) {
        this.players[media.id].autostart = true;
      }
    }
  }

  playAudio(media, volume = 1) {
    if (this.players[media.id]) {
      if (this.players[media.id].loaded) {
        //console.log('play audio' + media.source);
        this.players[media.id].volume.value = Tone.gainToDb(volume);
        this.players[media.id].start();
      }
    } else {
      this.addAudioPlayer(media, true, volume);
    }
  }

  doAction(action) {
    //console.log(action);
    switch (action.command) {

      case 'enter':
      if (this.currentVideo) {
        this.playing = this.props.isPreviewing;
        /*if (this.currentVideo.source !== 'webcam' && this.currentVideo.source.indexOf('giphy') === -1) {
          this.props.onSolo(this.props.feature.parentCharacter.id);
        }*/
        this.isMuted = false;
        if (this.currentState.pauseOtherAudio) {
          //this.volume = 1;
          this.props.onSolo(this.props.feature.parentCharacter.id);
        } else {
          //this.volume = 0;
          //this.isMuted = true;
        }
        setTimeout(() => {
          this.forceUpdate();
        }, 250);
      }
      break;

      case 'play-audio':
      let media = this.props.stepwise.score.getMedia(action.content);
      if (media && this.props.isPreviewing) {
        this.playAudio(media, action.volume);
      }
      break;

      default:
      break;
    }
  }

  setTransition(secs) {
    //console.log('set transition '+secs);
    this.transition = secs;
    //this.video.current.style.transition = `scale ${secs}s, transform ${secs}s, background-size ${secs}s, opacity ${secs}s, filter ${secs}s`;
  }

  setAudio(media) {
    if (this.currentStateAudio !== media) {
      this.currentStateAudio = media;
      if (media) {
        this.stateAudio.current.src = media.source;
        this.stateAudio.current.currentTime = 0;
        if (this.props.isPreviewing && this.currentState.isPlaying) {
          this.stateAudio.current.play().catch(err => null);
        } else {
          this.stateAudio.current.pause();
        }
      }
    } else if (this.currentStateAudio) {
      if (this.props.isPreviewing && this.currentState.isPlaying) {
        this.stateAudio.current.play().catch(err => null);
      } else {
        this.stateAudio.current.pause();
      }
    }
    this.forceUpdate();
  }

  mute() {
    //console.log('mute '+this.props.feature.id);
    //this.playing = false;
    this.volume = 0;
    this.isMuted = true;
    this.forceUpdate();
  }

  togglePlayPause() {
    if (!this.isMuted) {
      if (this.stateAudio.current) {
        if (this.stateAudio.current.paused) {
          this.stateAudio.current.play();
        } else {
          this.stateAudio.current.pause();
        }
      }
      this.forceUpdate();
    }
  }

  pause() {
    this.playing = false;
    this.forceUpdate();
  }

  updateLayout() {

  }

  /*handleStep(step) {
    var video;
    if (step) {
      switch (step.command) {
        case 'narrate':
        case 'speak':
        case 'think':
        case 'sing':
        var extension = window.getFilenameExtension(step.content);
        switch (extension) {

          case 'mp4':
          case 'mov':
          this.clearMedia();
          this.video = document.createElement('video');
          this.video.src = step.content;
          this.video.autoPlay = true;
          this.element.current.appendChild(this.video);
          this.video.currentTime = 0;
          this.video.style.objectFit = this.objectFit;
          this.video.play();
          setTimeout(() => { this.props.character.registerMedia(this.video); }, 10);
          break;

          case 'gif':
          case 'png':
          case 'jpg':
          case 'jpeg':
          this.video = null;
          break;

          case 'mp3':
          case 'ogg':
          this.clearMedia();
          this.stateAudio = document.createElement('audio');
          this.stateAudio.src = step.content;
          this.stateAudio.autoPlay = true;
          this.element.current.appendChild(this.stateAudio);
          this.stateAudio.currentTime = 0;
          this.stateAudio.play();
          setTimeout(() => { this.props.character.registerMedia(this.stateAudio); }, 10);
          break;

          default:
          break;
        }
        break;

        case 'pause':
        if (this.video) this.video.pause();
        if (this.stateAudio) this.stateAudio.pause();
        break;

        case 'play':
        if (this.video) this.video.play();
        if (this.stateAudio) this.stateAudio.play();
        break;

        case 'seekTo':
        var time = parseFloat(step.content);
        if (this.video && !isNaN(video.duration)) {
          if (step.content === 'random') {
            this.video.currentTime = Math.random() * this.video.duration;
          } else if (!isNaN(time)) {
            this.video.currentTime = time;
          }
        }
        /*if (audio) {
          if (step.content === 'random') {
            audio.currentTime = Math.random() * audio.duration;
          } else if (!isNaN(time)) {
            audio.currentTime = time;
          }
        }*
        break;

        case 'setLoop':
        if (this.video) this.video.loop = step.content === 'true';
        if (this.stateAudio) this.stateAudio.loop = step.content === 'true';
        break;

        case 'setFit':
        this.objectFit = step.content;
        if (this.video) this.video.style.objectFit = step.content;
        break;

        case 'setVolume':
        if (this.video) this.video.volume = parseFloat(step.content);
        if (this.stateAudio) this.stateAudio.volume = parseFloat(step.content);
        break;

        case 'clearVideo':
        this.clearVideo();
        break;

        default:
        break;
      }
    }
  }*/

  render() {
    let className = 'panel-audio';
    if (!this.currentStateAudio) {
      className += ' disable-interaction';
    }
    return (
      <div className={className} ref={this.element}>
        <audio
          ref={this.stateAudio}
          controls={this.props.feature.parentCharacter.visible && this.currentStateAudio}
          loop={this.currentState ? this.currentState.loop : false}
        />
      </div>
    )
  }
}

export default AudioFeature;
