import React from 'react';

class MediaCaption extends React.Component {

  handleStoryUpdate() {
    this.forceUpdate();
  }

  render() {
    if (this.props.media) {
      if (this.props.media.attribution) {
        let attribution;
        if (this.props.media.attributionURL) {
          attribution = <a href={this.props.media.attributionURL} target="_blank" rel="noopener noreferrer">{this.props.media.attribution}</a>
        } else {
          attribution = <span>{this.props.media.attribution}</span>
        }
        return (
          <div className="media-caption enable-interaction">
            <div className="caption-title">{this.props.media.name}</div>
            <div className="caption-credit">{attribution}</div>
          </div>
        )
      }
    }
    return <div></div>
  }
}

export default MediaCaption;
