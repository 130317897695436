import React from 'react';
import Slabtype from './Slabtype.js';

var WebFont = require('webfontloader');

class TextFeature extends React.Component {
  constructor(props) {
    super(props);
    this.currentState = null;
    this.key = 0;
    this.content = '';
    this.layout = 'standard';
    this.options = null;
    this.lastStep = null;
    this.concatenatedSteps = [];
    this.currentText = ''
    this.textWrapper = React.createRef();
    this.standardTextRef = React.createRef();
    this.slabtypeTextRef = React.createRef();
    this.fontName = 'sans-serif';
  }

  componentDidMount() {
    this.applyState(this.props.feature.defaultState);
  }

  shouldComponentUpdate() {
    return false;
  }

  handleStoryUpdate() {
    this.forceUpdate();
  }

  getTextRef() {
    if (this.layout === 'standard') {
      return this.standardTextRef;
    } else {
      return this.slabtypeTextRef;
    }
  }

  applyState(state) {
    // since changing the layout requires a new DOM element, handle it first
    if (this.currentState) {
      if (this.currentState.layout !== state.layout) {
        this.layout = state.layout;
        this.forceUpdate();
      }
    }
    this.currentState = state;
    let textRef = this.getTextRef();
    if (!textRef.current) {
      // needed to allow for swapping of DOM elements when layout changes
      setTimeout(() => this.applyState(state), 1);
    } else {
      for (let property in state) {
        switch (property) {

          case 'font':
          this.setFont(state[property]);
          break;

          case 'align':
          this.setAlign(state[property]);
          break;

          case 'content':
          this.setContent(state[property]);
          break;

          case 'mode':
          case 'stemAngle':
          case 'stemLength':
          case 'stemWidth':
          case 'stemPosition':
          case 'balloonType':
          this[property] = state[property];
          break;

          case 'width':
          case 'height':
          if (textRef.style) textRef.style.setProperty(property, state[property], 'important');
          break;

          case 'layout':
          this.layout = state[property];
          break;

          case 'textAlign':
          if (textRef.current.style) textRef.current.style[property] = state[property];
          /*if (this.textWrapper.current.style) {
            this.textWrapper.current.textAlign = state[property];
            this.textWrapper.current.justifyContent = state[property];
          }*/
          break;

          case 'backgroundColor':
          case 'padding':
          case 'lineHeight':
          case 'fontSize':
          case 'textShadow':
          if (textRef.current.style) textRef.current.style[property] = state[property];
          break;

          case 'margin':
          case 'overflow':
          if (this.textWrapper.current.style) {
            this.textWrapper.current.style[property] = state[property];
            // TODO: currently this only handles a single margin value, needs to handle multiple
            let totalMargin = (parseInt(state[property]) * 2);
            this.textWrapper.current.style.width = 'calc(100% - ' + totalMargin + 'px)';
            this.textWrapper.current.style.height = 'calc(100% - ' + totalMargin + 'px)';
          }
          break;

          default:
          if (this.textWrapper.current.style) this.textWrapper.current.style[property] = state[property];
          break;
        }
      }
    }
    this.forceUpdate();
  }

  doAction(action) {
    switch (action.command) {
      case 'speak':
      let extension = window.getFilenameExtension(action.content);
      if (extension.trim() === '') {
        if (action.append) {
          this.content += action.content;
          this.addConcatenatedAction(action);
        } else {
          this.content = action.content;
          this.key++;
          this.addConcatenatedAction(action, true);
        }
        this.lastStep = action;
      }
      this.setContent(this.content);
      if (this.layout === 'slabtype') {
        if (this.slabtypeTextRef.current) {
          this.slabtypeTextRef.current.forceUpdate();
        }
      }
      break;

      default:
      break;
    }
  }

  setFont(fontName) {
    this.fontName = fontName;
    if (this.textWrapper.current.style) {
      if (this.textWrapper.current.style.fontFamily !== fontName) {
        if (fontName !== '' && fontName !== 'sans-serif') {
          WebFont.load({google: {families: [fontName, fontName+':ital']}});
        }
        this.textWrapper.current.style.fontFamily = fontName;
      }
    }
  }

  setAlign(align) {
    var css = this.textWrapper.current.style;
    var justify;
    var temp = align.split(' ');
    if ((temp.length === 1) || (temp.length === 2)) {
      switch (temp[0]) {
        case 'left':
          justify = 'flex-start';
          break;
        case 'center':
          justify = 'center';
          break;
        case 'right':
          justify = 'flex-end';
          break;
        default:
          break;
      }
      if (temp.length === 1) {
        css.justifyContent = justify;
        css.textAlign = temp[0];
        css.alignItems = 'center';
      } else if (temp.length === 2) {
        switch (temp[1]) {
          case 'top':
            align = 'flex-start';
            break;
          case 'center':
            align = 'center';
            break;
          case 'bottom':
            align = 'flex-end';
            break;
          default:
            break;
        }
        css.justifyContent = justify;
        css.textAlign = temp[0];
        css.alignItems = align;
      }
    }
  }

  setContent(content) {
    this.content = content;
    //this.text.current.textContent = content;
    this.forceUpdate();
  }

  addConcatenatedAction(step, clearPrevious) {
    if (clearPrevious) {
      this.concatenatedSteps = [];
    }
    this.concatenatedSteps.push(step);
  }

  generateTextFromConcatenatedSteps() {
    this.content = '';
    var n = this.concatenatedSteps.length;
    for (var i=0; i<n; i++) {
      this.content += this.concatenatedSteps[i].content;
    }
  }

  handleStep(step) {
    var extension;
    if (step) {
      if (!this.props.isEchoing) {
        let textRef = this.getTextRef();
        var css = textRef.style;
        switch (step.command) {

          case 'narrate':
          case 'speak':
          case 'think':
          case 'sing':
          extension = window.getFilenameExtension(step.content);
          if (extension.trim() === '') {
            if (step.append) {
              this.content += step.content;
              this.addConcatenatedAction(step);
            } else {
              this.content = step.content;
              this.key++;
              this.addConcatenatedAction(step, true);
            }
            this.lastStep = step;
          }
          break;

          case 'option':
          this.addConcatenatedAction(step, !step.append);
          break;

          case 'setCaptionBackgroundColor':
          css.backgroundColor = step.content;
          break;

          case 'setCaptionPadding':
          css.padding = step.content;
          break;

          case 'setFont':
          var temp = step.content.split(':');
          WebFont.load({google: {families: [step.content]}});
          css.fontFamily = temp[0];
  				if (temp.length > 1) {
            css.fontWeight = temp[1];
          }
          break;

          case 'setFontSize':
          css.fontSize = step.content;
          break;

          case 'setFontWeight':
          css.fontWeight = step.content;
          break;

          case 'setForeColor':
          css.color = step.content;
          break;

          case 'setLetterSpacing':
          css.letterSpacing = step.content;
          break;

          case 'setLineHeight':
          css.lineHeight = step.content;
          break;

          /*case 'setTextAlign':
          var justify, align;
          var temp = step.content.split(' ');
          if ((temp.length === 1) || (temp.length === 2)) {
            switch (temp[0]) {
              case 'left':
                justify = 'flex-start';
                break;
              case 'center':
                justify = 'center';
                break;
              case 'right':
                justify = 'flex-end';
                break;
              default:
                break;
            }
            if (temp.length === 1) {
              css.justifyContent = justify;
              css.textAlign = temp[0];
              css.alignItems = 'center';
            } else if (temp.length === 2) {
              switch (temp[1]) {
                case 'top':
                  align = 'flex-start';
                  break;
                case 'center':
                  align = 'center';
                  break;
                case 'bottom':
                  align = 'flex-end';
                  break;
                default:
                  break;
              }
              css.justifyContent = justify;
              css.textAlign = temp[0];
              css.alignItems = align;
            }
          }
          break;*/

          case 'setTextShadow':
          css.textShadow = step.content;
          break;

          case 'setTextTransform':
          css.textTransform = step.content;
          break;

          case 'setPadding':
          css.margin = step.content;
          break;

          default:
          break;
        }
      } else {
        switch (step.command) {

          case 'narrate':
          case 'speak':
          case 'think':
          case 'sing':
          extension = window.getFilenameExtension(step.content);
          if (extension.trim() === '') {
            if (step.append) {
              this.content += step.content;
              this.addConcatenatedAction(step);
            } else {
              this.content = step.content;
              this.key++;
              this.addConcatenatedAction(step, true);
            }
            this.lastStep = step;
          }
          break;

          default:
          break;
        }
      }
    }
    this.currentText = '';
    if (this.concatenatedSteps.length > 0) {
      /*if (this.props.selectedPanelId === this.props.character.id) {
        for (step of this.concatenatedSteps) {
          switch (step.command) {
            case 'narrate':
            case 'speak':
            case 'think':
            case 'sing':
            this.currentText = step.content;
            break;
            default:
            break;
          }
        }
      } else {*/
        this.currentText = this.content;
      //}
    }
    //this.generateOptionsFromConcatenatedSteps();
    this.updateText();
  }

  mute() {

  }

  togglePlayPause() {

  }

  pause() {

  }

  updateLayout() {

  }

  updateText() {
    let textRef = this.getTextRef();
    if (textRef) textRef.textContent = this.currentText;
  }

  render() {
    let textLayer;
    let content = this.content.split(/(?:\r\n|\r|\n)/g).map((item, i) => {
      return <p key={i}>{item}</p>;
    });
    switch (this.layout) {
      case 'standard':
      textLayer = <div ref={this.standardTextRef} className="text-layer">{content}</div>;
      break;
      case 'slabtype':
      textLayer = <Slabtype
        ref={this.slabtypeTextRef}
        textState={this.currentState}
        content={this.content}
        fontName={this.fontName}
      />
      break;
      default:
      break;
    }
    return (
      <div ref={this.textWrapper} className="panel-text text-layer-wrapper disable-interaction">
        {textLayer}
      </div>
    )
  }
}

export default TextFeature;
