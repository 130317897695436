import React from 'react';
import PickerColumn from './PickerColumn.js'
import { isMobile } from  'react-device-detect';

class StatementPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      optionValues: {
        month: null,
        day: null,
        year: null,
        statement: null
      },
      optionIndexes: {
        month: -1,
        day: -1,
        year: -1,
        statement: -1
      },
      optionGroups: {
        month: [],
        day: [],
        year: [],
        statement: []
      }
    }
    let optionValues = this.state.optionValues;
    let optionIndexes = this.state.optionIndexes;
    this.hypotheticalValues = {
      month: optionValues.month,
      day: optionValues.day,
      year: optionValues.year,
      statement: optionValues.statement
    };
    this.hypotheticalIndexes = {
      month: optionIndexes.month,
      day: optionIndexes.day,
      year: optionIndexes.year,
      statement: optionIndexes.statement
    };
    this.fullMonths = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
    if (isMobile) {
      this.months = [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ];
    } else {
      this.months = this.fullMonths;
    }
    this.stepIndexesByDay = {};
    this.dayAndStepIndexArr = [];
    this.handleChange = this.handleChange.bind(this);
    this.scoreLoadCount = 0;
    this.pickerRefs = {
      month: React.createRef(),
      day: React.createRef(),
      year: React.createRef(),
      statement: React.createRef()
    };
  }

  reset() {
    this.setState({optionValues: {
      month: null,
      day: null,
      year: null,
      statement: null
    },
    optionIndexes: {
      month: -1,
      day: -1,
      year: -1,
      statement: -1
    }});
  }

  componentDidMount() {
    let optionGroups = this.state.optionGroups;
    let monthsAndYears = this.getMonthsAndYears();
    optionGroups.month = monthsAndYears.months;
    optionGroups.year = monthsAndYears.years;
    this.setState({optionGroups: optionGroups});
  }

  handleStepwiseEvent(type, obj) {
    //console.log(type, obj);
    switch (type) {

      case 'scoreLoaded':
      this.attributionChar = this.props.stepwise.score.getCharacter('attribution');
      this.populatePicker();
      this.scoreLoadCount++;
      break;

      case 'sequence':
      //this.populatePicker();
      break;

      case 'action':
      switch (obj.command) {
        case 'speak':
        //this.setState({currentAction: obj});
        break;
        default:
        break;
      }
      break;

      case 'step':
      if (obj.actions[0].command !== 'load-story') {
        let progress = this.getStatementProgress();
        this.props.onProgress(Math.max(0,progress));
      } else {
        let comp = obj.actions[0].content.substr(5,6);
        let year = comp.substr(0,4);
        let monthIndex = parseInt(comp.substr(4,2)) - 1;
        this.hypotheticalValues = {
          month: this.months[monthIndex],
          day: null,
          year: year,
          statement: null
        }
        this.hypotheticalIndexes = {
          month: monthIndex,
          day: -1,
          year: this.state.optionGroups.year.indexOf(year),
          statement: -1
        }
        this.lastGroupChanged = 'month';
      }
      this.updatePickers();
      break;

      default:
      break;
    }
  }

  updatePickers() {
    //console.log('--- update pickers ---');
    let sequence = this.props.stepwise.score.currentScene.currentSequence;
    let n = this.dayAndStepIndexArr.length;
    let optionValues = this.state.optionValues;
    let optionIndexes = this.state.optionIndexes;
    let monthChanged = false;
    let dayChanged = false;
    let yearChanged = false;
    let statementChanged = false;
    for (let i=0; i<n; i++) {
      if (sequence.stepIndex >= this.dayAndStepIndexArr[i].stepIndex) {
        let foundMatch = false;
        if (i < n - 1) {
          if (sequence.stepIndex < this.dayAndStepIndexArr[i+1].stepIndex) {
            foundMatch = true;
          }
        } else {
          foundMatch = true;
        }
        if (foundMatch) {
          if (optionValues.day !== this.dayAndStepIndexArr[i].day || this.lastGroupChanged === 'month' || this.lastGroupChanged === 'year') {
            dayChanged = true;
            optionValues.day = this.dayAndStepIndexArr[i].day;
            optionIndexes.day = this.state.optionGroups.day.indexOf(optionValues.day);
          }
          if (optionValues.year !== this.dayAndStepIndexArr[i].year) {
            yearChanged = true;
            optionValues.year = this.dayAndStepIndexArr[i].year;
            optionIndexes.year = this.state.optionGroups.year.indexOf(optionValues.year);
          }
          let statementIndex = this.getStatementIndexForStepIndex(sequence.stepIndex);
          if (optionIndexes.statement !== statementIndex && this.lastGroupChanged !== 'statement') {
            statementChanged = true;
            optionIndexes.statement = statementIndex;
            optionValues.statement = this.state.optionGroups.statement[optionIndexes.statement];
          }
          if (optionValues.month !== this.dayAndStepIndexArr[i].month || this.lastGroupChanged === 'year') {
            monthChanged = true;
            optionValues.month = this.dayAndStepIndexArr[i].month;
            optionIndexes.month = this.getMonthIndexForYear(optionValues.month, optionValues.year);
          }
          break;
        }
      }
    }
    if (monthChanged || dayChanged || yearChanged || statementChanged || sequence.stepIndex === 0) {
      //console.log('dirty!',monthChanged,dayChanged,yearChanged,statementChanged,optionValues.statement);
      this.setState({optionValues: optionValues, optionIndexes: optionIndexes});
      if (monthChanged || sequence.stepIndex === 0) this.pickerRefs.month.current.snapToIndex(optionIndexes.month, false, false);
      if (dayChanged || sequence.stepIndex === 0) this.pickerRefs.day.current.snapToIndex(optionIndexes.day, false, false);
      if (yearChanged || sequence.stepIndex === 0) this.pickerRefs.year.current.snapToIndex(optionIndexes.year, false, false);
      if (statementChanged || sequence.stepIndex === 0) this.pickerRefs.statement.current.snapToIndex(optionIndexes.statement, false, false);
      this.forceUpdate();
    }
    this.lastGroupChanged = null;
  }

  getStatementIndexForStepIndex(stepIndex) {
    let n = this.statementArr.length;
    for (let i=0; i<n; i++) {
      if (i < n - 1) {
        if (stepIndex >= this.statementArr[i].stepIndex && stepIndex < this.statementArr[i+1].stepIndex) {
          return i;
        }
      } else if (stepIndex >= this.statementArr[i].stepIndex) {
        return i;
      }
    }
    return -1;
  }

  populatePicker() {
    //console.log('----- populatePicker -----');
    let days = [];
    let statements = [];
    let data;
    let sequence = this.props.stepwise.score.currentScene.currentSequence;
    this.stepIndexesByDay = {};
    this.dayAndStepIndexArr = [];
    this.statementArr = [];
    let statementStepCounter = 0;
    sequence.steps.forEach((step, stepIndex) => {
      step.actions.forEach(action => {
        if (action.targetCharacter.id === 'timeline' && action.command !== 'load-story') {
          statementStepCounter = 0;
          let date = new Date(action.content);
          let day = date.getDate();
          if (this.stepIndexesByDay[day.toString()] === undefined) {
            this.stepIndexesByDay[day.toString()] = stepIndex;
            data = {
              month: this.months[date.getMonth()],
              day: day.toString(),
              year: date.getFullYear().toString(),
              stepIndex:stepIndex
            };
            this.dayAndStepIndexArr.push(data);
          }
          if (days.indexOf(day) === -1) {
            days.push(day);
          }
        } else if (statementStepCounter === 1) {
          if (action.targetCharacter.id === 'narrator' && action.command === 'speak') {
            this.statementArr.push({statement:action.content, stepIndex: stepIndex - 1});
            if (data.statementIndex === undefined) {
              data.statementIndex = this.statementArr.length - 1;
            }
            statements.push(action.content);
          }
        } else if (statementStepCounter > 1 && statements[statements.length - 1].length < 90 && isNaN(new Date(action.content))) {
          if (action.targetCharacter.id === 'narrator' && action.command === 'speak') {
            statements[statements.length - 1] += ' ' + action.content;
          }
        }
      })
      statementStepCounter++;
    });
    days.sort(function(a, b) {
      return a - b;
    });
    let dayStrings = [];
    days.forEach(day => {
      dayStrings.push(day.toString());
    })
    let optionValues = this.state.optionValues;
    optionValues.day = dayStrings[0];
    let optionGroups = this.state.optionGroups;
    optionGroups.day = dayStrings;
    optionGroups.statement = statements;
    this.setState({optionValues: optionValues, optionGroups: optionGroups});
    this.conformHypotheticalValues();
  }

  getMonthsAndYears() {
    let monthNames = [];
    let years = []
    if (this.props.manifest) {
      this.props.manifest.forEach(monthData => {
        let year = monthData.date.toString().substr(0, 4);
        if (years.indexOf(year) === -1) {
          years.push(year);
        }
        let fullMonth = monthData.title.split(' ')[0];
        monthNames.push(this.months[this.fullMonths.indexOf(fullMonth)]);
      });
    }
    return {months:monthNames, years: years};
  }

  conformHypotheticalValues(commitValues = true) {
    //console.log('conformHypotheticalValues',this.hypotheticalValues);
    let n;
    let values = this.hypotheticalValues;
    let indexes = this.hypotheticalIndexes;
    let optionValues = this.state.optionValues;
    let optionIndexes = this.state.optionIndexes;
    let optionGroups = this.state.optionGroups;

    if (!values.month) {
      optionValues.month = optionGroups.month[0];
      optionIndexes.month = 0;
    } else if (!this.getMonthIndexForYear(values.month, values.year)) {
      let index;
      let currentMonthIndex = parseInt(values.year * 100) + this.months.indexOf(values.month);
      n = optionGroups.month.length;
      let minMonthDifference = 99999;
      let newMonth;
      let currentYear = 2020;
      for (let i=0; i<n; i++) {
        if (optionGroups.month[i] === this.months[0]) {
          currentYear++;
        }
        index = parseInt(currentYear * 100) + this.months.indexOf(optionGroups.month[i]);
        if (Math.abs(index - currentMonthIndex) < minMonthDifference) {
          minMonthDifference = Math.abs(index - currentMonthIndex);
          newMonth = optionGroups.month[i];
        }
      }
      optionValues.month = newMonth;
      optionIndexes.month = this.getMonthIndexForYear(newMonth, values.year);
    } else {
      optionValues.month = values.month;
      optionIndexes.month = this.getMonthIndexForYear(values.month, values.year);
    }

    if (!values.day) {
      optionValues.day = optionGroups.day[0];
      optionIndexes.day = 0;
    } else if (optionGroups.day.indexOf(values.day) === -1) {
      let currentDay = parseInt(optionValues.day);
      n = optionGroups.day.length;
      let minDayDifference = 99;
      let day;
      let newDay;
      let newDayIndex = -1;
      for (let i=0; i<n; i++) {
        day = parseInt(optionGroups.day[i]);
        if (Math.abs(day - currentDay) < minDayDifference) {
          minDayDifference = Math.abs(day - currentDay);
          newDay = day;
          newDayIndex = i;
        }
      }
      //console.log('conform day',newDay,newDayIndex);
      optionValues.day = newDay.toString();
      optionIndexes.day = newDayIndex;
    } else {
      optionValues.day = values.day;
      optionIndexes.day = indexes.day;
      //console.log('transfer day',optionValues.day);
    }

    if (!values.year) {
      optionValues.year = optionGroups.year[0];
      optionIndexes.year = 0;
    } else {
      optionValues.year = values.year;
      optionIndexes.year = indexes.year;
    }

    if (indexes.statement === -1) {
      optionValues.statement = optionGroups.statement[0];
      optionIndexes.statement = 0;
    /*} else {
      optionIndexes.statement = this.dayAndStepIndexArr[optionIndexes.day].statementIndex;
      optionValues.statement = optionGroups.statement[optionIndexes.statement];*/
    }

    if (commitValues) {
      if (this.scoreLoadCount > 0) this.executeStepFromIndex(this.stepIndexesByDay[optionValues.day]);
      this.setState({optionValues: optionValues, optionIndexes: optionIndexes});
    }

    //console.log('conformed values',optionValues);
    return optionValues;
  }

  getStatementProgress() {
    let sequence = this.props.stepwise.score.currentScene.currentSequence;
    let startIndex = this.getStartingIndexOfCurrentStatement();
    let endIndex = this.getStartingIndexOfNextStatement();
    let progress = 0;
    if (endIndex - startIndex > 3) {
      progress = (sequence.stepIndex - startIndex) / parseFloat(endIndex - 1 - startIndex);
    } else {
      progress = -1;
    }
    return progress;
  }

  getStartingIndexOfCurrentStatement() {
    let sequence = this.props.stepwise.score.currentScene.currentSequence;
    let isFirstStepOfStatement = false;
    let index = sequence.stepIndex;
    let step;
    do {
      step = sequence.steps[index];
      isFirstStepOfStatement = step.containsActionForCharacter('speak', this.attributionChar);
      index--;
    } while (!isFirstStepOfStatement && index > -1);
    index++;
    return index;
  }

  getStartingIndexOfNextStatement() {
    let sequence = this.props.stepwise.score.currentScene.currentSequence;
    let isFirstStepOfStatement = false;
    let index = sequence.stepIndex + 1;
    let step;
    do {
      step = sequence.steps[index];
      isFirstStepOfStatement = step.containsActionForCharacter('speak', this.attributionChar);
      index++;
    } while (!isFirstStepOfStatement && index < sequence.steps.length);
    index--;
    return index;
  }

  setHypotheticalValues(groupId, index) {
    let optionValues = this.state.optionValues;
    let optionIndexes = this.state.optionIndexes;
    this.hypotheticalValues = {
      month: this.fullMonths[this.months.indexOf(optionValues.month)],
      day: optionValues.day,
      year: optionValues.year,
      statement: optionValues.statement
    }
    this.hypotheticalIndexes = {
      month: optionIndexes.month,
      day: optionIndexes.day,
      year: optionIndexes.year,
      statement: optionIndexes.statement
    }
    this.hypotheticalValues[groupId] = this.state.optionGroups[groupId][index];
    this.hypotheticalIndexes[groupId] = index;
    if (groupId === 'month') {
      this.hypotheticalValues.month = this.fullMonths[this.months.indexOf(this.state.optionGroups.month[index])];
      this.hypotheticalValues.year = this.getYearForMonthIndex(this.hypotheticalIndexes.month);
      this.hypotheticalIndexes.year = this.state.optionGroups.year.indexOf(this.hypotheticalValues.year);
    }
    //console.log(this.hypotheticalValues,this.hypotheticalIndexes);
  }

  getYearForMonthIndex(monthIndex) {
    let currentYear = 2020;
    let n = this.state.optionGroups.month.length;
    for (let i=0; i<n; i++) {
      if (this.state.optionGroups.month[i] === this.months[0]) {
        currentYear++;
      }
      if (i === monthIndex) {
        return currentYear;
      }
    }
    return -1;
  }

  getMonthIndexForYear(month, year) {
    let monthIndexes = [];
    let yearsForMonthIndexes = [];
    let currentYear = 2020;
    this.state.optionGroups.month.forEach((monthOption, index) => {
      if (monthOption === this.months[0]) {
        currentYear++;
      }
      if (monthOption === month) {
        monthIndexes.push(index);
        yearsForMonthIndexes.push(currentYear)
      }
    });
    let index = yearsForMonthIndexes.indexOf(parseInt(year));
    //console.log('getMonthIndexForYear',month,year,monthIndexes,index,monthIndexes[index]);
    return monthIndexes[index];
  }

  getManifestDataForValues(values) {
    let n = this.props.manifest.length;
    for (let i=0; i<n; i++) {
      if (this.props.manifest[i].title === values.month + ' ' + values.year) {
        return {title: this.props.manifest[i].title, index: i};
      }
    }
    return {title: this.props.manifest[0].title, index: 0};
  }

  handleChange(groupId, index) {
    this.lastGroupChanged = groupId;
    let optionValues = this.state.optionValues;
    let optionIndexes = this.state.optionIndexes;
    let manifestData;
    switch (groupId) {

      case 'month':
      this.setHypotheticalValues(groupId, index);
      manifestData = this.getManifestDataForValues(this.hypotheticalValues);
      this.props.onMonthSelected(manifestData.index);
      break;

      case 'day':
      this.executeStepFromIndex(this.stepIndexesByDay[this.state.optionGroups[groupId][index]]);
      break;

      case 'year':
      this.setHypotheticalValues(groupId, index);
      manifestData = this.getManifestDataForValues(this.conformHypotheticalValues(false));
      this.props.onMonthSelected(manifestData.index);
      break;

      case 'statement':
      if (this.statementArr[index]) {
        this.executeStepFromIndex(this.statementArr[index].stepIndex);
        optionValues[groupId] = this.state.optionGroups[groupId][index];
        optionIndexes[groupId] = index;
        this.setState({optionValues: optionValues, optionIndexes: optionIndexes});
      }
      break;

      default:
      break;
    }
  }

  executeStepFromIndex(stepIndex) {
    let sequence = this.props.stepwise.score.currentScene.currentSequence;
    let step = sequence.steps[Math.max(0, stepIndex - 1)];
    if (stepIndex === 0) {
      sequence.stepIndex = -1;
    } else {
      sequence.setCurrentStep(step);
    }
    this.props.stepwise.nextStep();
    this.props.stepwise.nextStep();
  }

  renderInner() {
    const {optionGroups, optionValues, optionIndexes} = this.state;
    const {itemHeight, height, onClick} = this.props;
    const highlightStyle = {
      height: itemHeight + 'px',
      marginTop: -(itemHeight / 2)
    };
    const columnNodes = [];
    for (let name in optionGroups) {
      columnNodes.push(
        <PickerColumn
          key={name}
          name={name}
          ref={this.pickerRefs[name]}
          options={optionGroups[name]}
          value={optionValues[name]}
          index={optionIndexes[name]}
          itemHeight={itemHeight}
          columnHeight={height}
          onChange={this.handleChange}
          onScroll={this.props.onScroll}
          onClick={onClick} />
      );
    }
    return (
      <div className="picker-inner">
        {columnNodes}
        <div className="picker-highlight" style={highlightStyle}></div>
      </div>
    );
  }

  render() {
    const style = {
      height: this.props.height + 'px'
    };
    let className = 'statement-picker';
    if (!this.props.interactable) {
      className += ' no-pointer-events';
    }
    return <div className={className} style={style}>
      {this.renderInner()}
    </div>
  }
}

export default StatementPicker;
