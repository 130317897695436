import React from 'react';
import { Stepwise, FrameState } from './stepwise/stepwise-v2.js';
import VideoFeature from './VideoFeature.js';
import TextFeature from './TextFeature.js';
import ImageFeature from './ImageFeature.js';
import AudioFeature from './AudioFeature.js';
import PanelMatte from './PanelMatte.js';

class Panel extends React.Component {
  constructor(props) {
    super(props);
    this.isEchoing = false;
    this.transition = .5;
    this.gridLayout= {left:0, top:0, width: 1, height: 1}
    this.margins = {left:0, top:0, right:0, bottom:0};
    this.baseElement = React.createRef();
    this.media = React.createRef();
    this.image = React.createRef();
    this.textFeature = React.createRef();
    this.matte = React.createRef();
  }

  componentDidMount() {
    this.updateLayout();
    this.setTransition(.5);
  }

  shouldComponentUpdate() {
    return false;
  }

  doUpdate() {
    this.forceUpdate();
  }

  handleStoryUpdate() {
    this.forceUpdate();
    Object.values(this.featureRefs).forEach(featureRef => {
      if (featureRef) featureRef.handleStoryUpdate()
    });
  }

  reflow() {
    //console.log('reflow: '+this.props.character.id);
    let a = this.baseElement.current.offsetHeight;
    return a;
  }

  handleStepwiseEvent(type, obj) {
    switch (type) {

      case 'sequence':
      let feature = this.props.character.getFeatureForType('audio');
      if (this.featureRefs[feature.id]) {
        this.featureRefs[feature.id].preloadAudioForSequence(obj);
      }
      break;

      case 'state':
      switch (obj.type) {
        case 'frame':
        this.applyState(obj);
        break;
        default:
        if (this.featureRefs[obj.targetFeature.id]) {
          this.featureRefs[obj.targetFeature.id].applyState(obj);
        }
        break;
      }
      break;

      case 'action':
      if (obj.command !== 'move') {
        if (obj.targetFeature) {
          this.featureRefs[obj.targetFeature.id].doAction(obj);
        } else {
          Object.values(this.featureRefs).forEach(featureRef => {
            if (featureRef) featureRef.doAction(obj);
          });
        }
      } else {
        let a = obj.content.split(' ');
        this.setLayout(parseFloat(a[0]), parseFloat(a[1]), parseFloat(a[2]), parseFloat(a[3]));
        this.updateLayout();
      }
      break;

      /*case 'nextStep':
      Object.values(this.featureRefs).forEach((featureRef, i) => {
        if (featureRef) {
          if (featureRef.handleStep) featureRef.handleStep();
        }
      });
      break;*/

      case 'step':
      this.lastStep = obj;
      break;

      default:
      break;
    }
  }

  applyState(state) {
    this.currentState = state;
    this.setTransition(state.transitionDuration);
    for (let property in state) {
      switch (property) {

        case 'layout':
        if (state.enforceLayout) {
          let a = state[property].split(' ');
          this.setLayout(parseFloat(a[0]), parseFloat(a[1]), parseFloat(a[2]), parseFloat(a[3]));
        }
        break;

        case 'margin':
        this.setMargin(state[property]);
        break;

        case 'depth':
        this.baseElement.current.style.zIndex = parseInt(state[property]);
        break;

        case 'enforceLayout':
        // nothing; temporary fix so that layout can be enforced when restoring
        // from history but not at other times
        break;

        default:
        if (property !== 'length' && property !== 'parentRule' && property !== 'type') {
          this.baseElement.current.style[property] = state[property];
        }
        break;
      }
    }
    if (state.enforceLayout) {
      this.updateLayout();
    }
  }

  getStates() {
    let states = [];
    states.push(this.getCurrentFrameState());
    Object.values(this.featureRefs).forEach(featureRef => {
      if (featureRef) {
        states.push(this.props.stepwise.score.currentScene.defaultSequence.getCurrentStateForFeatureInStep(featureRef.props.feature, this.lastStep, true, true));
      }
    });
    return states;
  }

  getCurrentFrameState() {
    let data = {
      "type": "frame",
      "character": this.props.character.id,
      "layout": this.gridLayout.left + " " + this.gridLayout.top + " " + this.gridLayout.width + " " + this.gridLayout.height,
      "margin": this.margins.left + " " + this.margins.top + " " + this.margins.right + " " + this.margins.bottom,
      "backgroundColor": this.baseElement.current.style.backgroundColor,
      "opacity": this.baseElement.current.style.opacity,
      "border": this.baseElement.current.style.border,
      "overflow": this.baseElement.current.style.baseElement,
      "enforceLayout": true
    }
    //console.log('current frame',this.props.character.id,data);
    let state = this.currentState;
    if (!state) {
      let feature = this.props.character.getFeatureForType('frame');
      state = this.props.stepwise.score.currentScene.defaultSequence.getCurrentStateForFeatureInStep(feature, this.lastStep, true, true);
    }
    if (state.targetFeature) {
      data.feature = state.targetFeature.id;
    }
    let frameState = new FrameState(data, this.props.stepwise.score);
    frameState.setupReferences();
    return frameState;
  }

  mute() {
    Object.values(this.featureRefs).forEach(featureRef => {
      if (featureRef) featureRef.mute();
    })
  }

  togglePlayPause() {
    Object.values(this.featureRefs).forEach(featureRef => {
      if (featureRef) featureRef.togglePlayPause();
    })
  }

  pause() {
    Object.values(this.featureRefs).forEach(featureRef => {
      if (featureRef) featureRef.pause();
    })
  }

  /*handleStep(step) {
    var isEchoing = false;
    if (step.target) {
      if (step.target.constructor.name === 'Character') {
        if (step.target.echoingCharacters.indexOf(this.props.character) !== -1) {
          isEchoing = true;
        }
      }
    }
    if (step.target) {
      if (step.target.id === this.props.character.id) {
        var element = document.getElementById(this.props.character.id+'-panel');
        var temp, pos;
        switch (step.command) {
          case 'moveTo':
          pos = this.getPositionFromString(step.content);
          this.moveTo(pos.left, pos.top);
          break;

          case 'setFilter':
          var css = this.baseElement.current.style;
          css.filter = step.content;
          break;

          case 'setLayout':
          temp = step.content.split(' ');
          this.setLayout(parseFloat(temp[0]), parseFloat(temp[1]), parseFloat(temp[2]), parseFloat(temp[3]));
          break;
          case 'setMargins':
          this.margins = this.getMarginsFromString(step.content);
          break;
          case 'setPosition':
          pos = this.getPositionFromString(step.content);
          this.setPosition(pos.left, pos.top);
          break;
          case 'setBackColor':
          this.setBackgroundColor(step.content);
          break;
          case 'setTransition':
          this.setTransition(parseFloat(step.content));
          break;
          case 'setOpacity':
          this.setOpacity(step.content);
          break;

          case 'setTextAlign':
          var css = this.baseElement.current.style;
          var justify, align;
          var temp = step.content.split(' ');
          if ((temp.length === 1) || (temp.length === 2)) {
            switch (temp[0]) {
              case 'left':
                justify = 'flex-start';
                break;
              case 'center':
                justify = 'center';
                break;
              case 'right':
                justify = 'flex-end';
                break;
              default:
                break;
            }
            if (temp.length === 1) {
              css.justifyContent = justify;
              css.textAlign = temp[0];
              css.alignItems = 'center';
            } else if (temp.length === 2) {
              switch (temp[1]) {
                case 'top':
                  align = 'flex-start';
                  break;
                case 'center':
                  align = 'center';
                  break;
                case 'bottom':
                  align = 'flex-end';
                  break;
                default:
                  break;
              }
              css.justifyContent = justify;
              css.textAlign = temp[0];
              css.alignItems = align;
            }
          }
          break;

          default:
          break;
        }
        this.isEchoing = false;
      } else if (isEchoing) {
        this.isEchoing = true;
      }
    }
    if (this.environment.current) this.environment.current.handleStep(step);
    this.media.current.handleStep(step);
    this.image.current.handleStep(step);
    if (this.caption.current) this.caption.current.handleStep(step);
    this.matte.current.handleStep(step);
  }*/

  getPositionFromString(str) {
		var x, y, temp = str.split(' ');
		if (isNaN(parseFloat(temp[0]))) {
			switch (temp[0]) {
				case 'offleft':
					x = -this.gridLayout.width;
					break;
				case 'left':
					x = 0;
					break;
				case 'center':
					x = (this.props.grid.columns - this.gridLayout.width) * .5;
					break;
				case 'right':
					x = this.props.grid.columns - this.gridLayout.width;
					break;
				case 'offright':
					x = this.props.grid.columns;
					break;
				case 'offtop':
					y = this.gridLayout.height;
					break;
				case 'top':
					y = 0;
					break;
				case 'bottom':
					y = this.props.grid.rows - this.gridLayout.height;
					break;
				case 'offbottom':
					y = this.props.grid.rows;
					break;
        default:
          break;
      }
			if (temp.length > 1) {
				switch (temp[1]) {
					case 'offtop':
						y = this.gridLayout.height;
						break;
					case 'top':
						y = 0;
						break;
					case 'center':
						y = (this.props.grid.rows - this.gridLayout.height) * .5;
						break;
					case 'bottom':
						y = this.props.grid.rows - this.gridLayout.height;
						break;
					case 'offbottom':
						y = this.props.grid.rows;
						break;
          default:
            break;
        }
			} else if (temp.length === 1) {
				if (temp[0] === 'center') {
					y = (this.props.grid.rows - this.gridLayout.height) * .5;
        }
      }
		} else {
			x = temp[0];
			y = temp[1];
    }
		return {left: x, top: y};
  }

  setMargin(marginString) {
    let temp = marginString.split (' ');
    if (temp.length === 1) {
      this.margins.top = parseFloat(temp[0]);
      this.margins.bottom = parseFloat(temp[0]);
      this.margins.left = parseFloat(temp[0]);
      this.margins.right = parseFloat(temp[0]);
    } else if (temp.length === 2) {
      this.margins.top = parseFloat(temp[0]);
      this.margins.bottom = parseFloat(temp[0]);
      this.margins.left = parseFloat(temp[1]);
      this.margins.right = parseFloat(temp[1]);
    } else if (temp.length === 3) {
      this.margins.top = parseFloat(temp[0]);
      this.margins.bottom = parseFloat(temp[2]);
      this.margins.left = parseFloat(temp[1]);
      this.margins.right = parseFloat(temp[1]);
    } else if (temp.length === 4) {
      this.margins.top = parseFloat(temp[0]);
      this.margins.bottom = parseFloat(temp[2]);
      this.margins.left = parseFloat(temp[3]);
      this.margins.right = parseFloat(temp[1]);
    }
  }

  setLayout(x, y, w, h) {
    this.gridLayout.left = x;
    this.gridLayout.top = y;
    this.gridLayout.width = w;
    this.gridLayout.height = h;
  }

  setTransition(secs) {
    //console.log('set transition: '+this.props.character.fullName+' '+secs);
    this.transition = secs;
    this.baseElement.current.style.transition = `width ${secs}s, height ${secs}s, background-color ${secs}s, opacity ${secs}s, filter ${secs}s, top ${secs}s, left ${secs}s`;
  }

  setPosition(left, top) {
    //console.log('set position: '+this.props.character.id+' '+left+' '+top);
    if (!isNaN(left) && !isNaN(top)) {
      var currentTransition = this.transition;
      this.setTransition(0);
      this.gridLayout.left = left;
      this.gridLayout.top = top;
      this.reflow();
      setTimeout(() => this.updateLayout(), 1000);
      this.setTransition(currentTransition);
    }
  }

  moveTo(left, top) {
    if (!isNaN(left) && !isNaN(top)) {
      this.gridLayout.left = left;
      this.gridLayout.top = top;
      this.updateLayout();
    }
  }

  updateLayout(unit) {
    if (unit) this.unit = unit;
    //console.log('updateLayout: '+this.props.character.fullName+' / '+this.gridLayout.left+' '+this.gridLayout.top+' '+this.gridLayout.width+' '+this.gridLayout.height);
    var css = this.baseElement.current.style;
    var grid = this.props.grid;
    let border = parseInt(this.baseElement.current.style.border);
    if (isNaN(border)) border = 0;
    let borderOffset = 0;
    css.left = 'calc(' + (this.gridLayout.left / grid.columns * 100) + '% + ' + this.margins.left + 'px)';
    css.top = 'calc(' + (this.gridLayout.top / grid.rows * 100) + '% + ' + this.margins.top + 'px)';
    css.width = 'calc(' + (this.gridLayout.width / grid.columns * 100) + '% - ' + (this.margins.left + this.margins.right + borderOffset) + 'px)';
    css.height = 'calc(' + (this.gridLayout.height / grid.rows * 100) + '% - ' + (this.margins.top + this.margins.bottom + borderOffset) + 'px)';
    this.props.character.features.forEach(feature => {
      let featureRef = this.featureRefs[feature.id];
      if (featureRef) {
        featureRef.updateLayout(this.unit);
      }
    });
  }

  render() {
    this.featureRefs = {};
    let className = 'panel';
    if (!this.props.character.visible) {
      className += ' hidden';
    }
    var features = this.props.character.features.map((feature, index) => {
      switch (feature.type) {

        case Stepwise.FeatureTypes.TEXT:
        return <TextFeature
          key={index}
          ref={ref => { this.featureRefs[feature.id] = ref; }}
          feature={feature}
          isEchoing={this.isEchoing}
        />

        case Stepwise.FeatureTypes.IMAGE:
        return <ImageFeature
          key={index}
          ref={ref => { this.featureRefs[feature.id] = ref; }}
          feature={feature}
          stepwise={this.props.stepwise}
        />

        case Stepwise.FeatureTypes.AUDIO:
        return <AudioFeature
          key={index}
          ref={ref => { this.featureRefs[feature.id] = ref; }}
          feature={feature}
          stepwise={this.props.stepwise}
          isPreviewing={this.props.isPreviewing}
        />

        case Stepwise.FeatureTypes.VIDEO:
        return <VideoFeature
          key={index}
          ref={ref => { this.featureRefs[feature.id] = ref; }}
          feature={feature}
          stepwise={this.props.stepwise}
          onSolo={this.props.onSolo}
          gridLayout={this.gridLayout}
          isPreviewing={this.props.isPreviewing}
        />

        default:
        return null;
      }
    });
    return (
      <div id={this.props.character.id+"-panel"} ref={this.baseElement} className={className}>
        {/*<PanelAudioVideo ref={this.media} character={this.props.character}/>
        <PanelImage ref={this.image}/>
        <PanelCaption ref={this.caption} isEchoing={this.isEchoing}/>*/}
        {features}
        <PanelMatte ref={this.matte}/>
      </div>
    )
  }
}

export default Panel;
