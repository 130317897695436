import React from 'react';
import './App.css';
import './stepworks/App.css';
import { Stepwise } from './stepworks/stepwise/stepwise-v2.js';
import Stage from './stepworks/Stage.js'
//import Timeline from './Timeline.js'
import Attribution from './Attribution.js'
import AudioPlayer from './AudioPlayer.js'
import StatementPicker from './StatementPicker.js'
import { isMobile } from  'react-device-detect';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.baseCharactersToIgnore = ['timeline','attribution','source','interview-audio'];
    this.soundtrackCharacters = ['soundtrack','medium-fx-char','cello-char','violin-char','elec-guitar-char','metal-string-char','ambience','click'];
    this.state = {
      message: 'Loading...',
      messageVisible: true,
      welcomeVisible: true,
      scoreLoaded: false,
      manifest: null,
      stepwise: null,
      charactersToIgnore: this.baseCharactersToIgnore,
      audioMode: 'all',
      isLoading: false,
      lastScoreLoaded: null,
      selectedTab: 'about',
      autoplay: false,
      random: false
    }
    this.keyCodesToIgnore = [9, 13, 16, 17, 18, 20, 27, 37, 224, 91, 93];
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleProgress = this.handleProgress.bind(this);
    this.handleTabSelected = this.handleTabSelected.bind(this);
    this.stageRef = React.createRef();
    //this.timelineRef = React.createRef();
    this.statementPickerRef = React.createRef();
    this.attributionRef = React.createRef();
    this.audioPlayerRef = React.createRef();
    this.stepwise = null;
    this.beatCounter = 0;
    this.isInteracting = false;
  }

  componentDidMount() {
    console.log('version 1.0.6 build 42');
    this.loadManifest();
    document.addEventListener("keydown", this.handleKeyDown, false);
    this.getRandomStatus();
    this.getAutoPlayStatus();
    if (this.state.autoplay) {
      this.beat = setInterval(this.handleBeat.bind(this), 1000);
    }
  }

  getAutoPlayStatus() {
    let searchParams = new URLSearchParams(window.location.search);
    let autoplay = searchParams.get('autoplay');
    this.setState({autoplay: autoplay === '1'});
  }

  getRandomStatus() {
    let path = window.location.pathname;
    this.setState({random: path === '/random'});
  }

  toggleAutoplay(evt) {
    let autoplay = !this.state.autoplay;
    if (autoplay) {
      let interval = 1000;
      if (evt.altKey) {
        interval *= .5;
      }
      this.beat = setInterval(this.handleBeat.bind(this), interval);
    } else {
      clearInterval(this.beat);
    }
    this.setState({autoplay: autoplay});
  }

  handleBeat() {
    if (!this.state.welcomeVisible && !this.state.messageVisible && !this.isInteracting) {
      if (this.beatCounter === 0) {
        this.stepwise.nextStep();
      } else {
        this.beatCounter--;
      }
    }
  }

  handleAudioEnded() {
    this.beatCounter = 0;
  }

  handleKeyDown(e) {
    e = e || window.event;
    switch (e.keyCode) {
      case 37: // left arrow
      this.previousStep();
      e.preventDefault();
      break;
      default:
      break;
    }
  }

  previousStep() {
    let scene = Object.values(this.stepwise.score.scenes)[0];
    if (scene.currentSequence.stepIndex > 0) {
      scene.currentSequence.stepIndex -= 2;
      this.stepwise.nextStep();
      //this.timelineRef.current.updateCurrentAction();
    }
  }

  handleStoryUpdate() {
    if (this.stageRef.current) {
      this.stageRef.current.handleStoryUpdate();
    }
  }

  handleStepwiseEvent(type, obj) {
    //console.log(type, obj);
    if ((type === 'action' || type === 'state') && this.state.messageVisible && !this.state.welcomeVisible) {
      this.setState({messageVisible: false});
    }
    if (type === 'scoreLoaded') {
      this.calculateAutoplayDuration();
      if (!this.state.scoreLoaded) {
        if (isMobile) {
          this.setState({
            scoreLoaded: true,
            message: <span>Tap to step through each statement<br/><br/>Headphones or speakers recommended<br/><br/>Tap <img src="images/audio-all.png" alt="Audio waveform icon"/> to mute</span>
          });
        } else {
          this.setState({
            scoreLoaded: true,
            message: <span>Click or press the → key to step through each statement<br/><br/>Headphones or speakers recommended<br/><br/>Click <img src="images/audio-all.png" alt="Audio waveform icon"/> to mute</span>
          });
        }
      //} else {
        //setTimeout(() => this.stepwise.nextStep(), 1); // hold off for a frame so the loaded event has a chance to send
      }
      let scene = Object.values(this.stepwise.score.scenes)[0];
      scene.setSequence(scene.defaultSequence);
      console.log('sequence: ' + scene.currentSequence.id);
      this.handleStoryUpdate();
    }
    switch (type) {
      case 'action':
      switch (obj.targetCharacter.id) {

        case 'timeline':
        if (obj.command === 'load-story') {
          this.setState({isLoading: true});
          setTimeout(() => this.loadStoryFromURL(obj.content), 1);
          if (obj.content === 'data/' + this.state.manifest[0].date + '.json') {
            if (this.statementPickerRef.current) {
              this.statementPickerRef.current.reset();
            }
          }
        } else if (this.statementPickerRef.current) {
          this.statementPickerRef.current.handleStepwiseEvent(type, obj);
        }
        break;

        case 'attribution':
        case 'source':
        if (this.attributionRef.current) {
          this.attributionRef.current.handleStepwiseEvent(type, obj);
        }
        break;

        case 'narrator':
        if (obj.command === 'speak') {
          this.beatCounter = this.getBeatCountForContent(obj.content);
        }
        break;

        default:
        break;

      }
      break;

      case 'state':
      if (this.audioPlayerRef.current) {
        this.audioPlayerRef.current.handleStepwiseEvent(type, obj);
      }
      break;

      case 'scoreLoaded':
      case 'sequence':
      case 'step':
      if (this.statementPickerRef.current) {
        this.statementPickerRef.current.handleStepwiseEvent(type, obj);
      }
      if (this.attributionRef.current) {
        this.attributionRef.current.handleStepwiseEvent(type, obj);
      }
      break;

      default:
      break;
    }
  }

  getBeatCountForContent(content) {
    let beatCount = 0;
    let temp = content.split(' ');
    if (temp.length > 9) { // long statements
      beatCount = (Math.round((temp.length * .5) / 3.0) * 3);
    } else if (content[content.length - 1] === '.') { // ending with a period
      beatCount = 2;
    } else if (!isNaN(new Date(content))) { // dates
      beatCount = 2;
    } else if (content.toLowerCase() === '[medical request]') { // medical request intros
      beatCount = 2;
    } else if (content.toLowerCase() === '[listen]') { // audio
      beatCount = -1;
    } else if (temp.length < 5) { // short
      beatCount = 0;
    } else { // default
      beatCount = 2;
    }
    return beatCount;
  }

  calculateAutoplayDuration() {
    let beatCount = 0;
    this.state.stepwise.score.currentScene.currentSequence.steps.forEach(step => {
      step.actions.forEach(action => {
        if (action.command === 'speak' && action.targetCharacter.id === 'narrator') {
          beatCount += this.getBeatCountForContent(action.content);
          beatCount++;
        }
      })
    })
    console.log('total duration: ' + (beatCount / 60.0) + ' minutes');
  }

  closeWelcomeMessage() {
    this.stepwise.inputManager.setEnabled(true);
    this.setState({welcomeVisible: false});
  }

  handleProgress(progress) {
    if (this.attributionRef.current) {
      this.attributionRef.current.handleProgress(progress);
    }
  }

  loadManifest() {
    var request = new Request('data/manifest.json');
    fetch(request)
      .then(response => response.json())
      .then(json => {
        this.setState({manifest: json});
        let index = 0;
        if (this.state.random) {
          index = Math.floor(Math.random() * this.state.manifest.length);
        }
        this.loadStoryFromURL('data/' + this.state.manifest[index].date + '.json');
      })
      .catch(error => {
        console.error(error);
      });
  }

  loadStoryFromURL(url) {
    console.log('load story: '+url);
    if (this.stepwise) {
      this.stepwise.inputManager.setEnabled(false);
      this.stepwise.dispose();
    }
    this.stepwise = new Stepwise({
      element: document.getElementById('stage'),
      keyInput: true,
      clickInput: true,
      tapInput: true,
      gamepadInput: true,
      keyCodesToIgnore: this.keyCodesToIgnore
    });
    this.stepwise.inputManager.setEnabled(false);
    this.stepwise.eventManager.addListener(this.handleStepwiseEvent.bind(this));
    var request = new Request(url);
    fetch(request)
      .then(response => response.json())
      .then(json => {
        this.stepwise.load(json, 'json');
        this.setState({
          stepwise: this.stepwise,
          isLoading: false
        });
        if (!this.state.welcomeVisible) {
          this.stepwise.inputManager.setEnabled(true);
        }
        this.handleStoryUpdate();
      })
      .catch(error => {
        console.error(error);
      });
  }

  handleMonthSelected(monthIndex) {
    console.log('handleMonthSelected',monthIndex);
    let newScore = this.state.manifest[monthIndex].date + '.json';
    if (this.state.lastScoreLoaded !== newScore) {
      this.setState({isLoading: true, lastScoreLoaded: newScore});
      let filename = '/data/' + this.state.manifest[monthIndex].date + '.json';
      this.loadStoryFromURL(filename);
    }
  }

  handleToggleAudio() {
    switch (this.state.audioMode) {
      case 'all':
      this.setState({audioMode: 'voice-only', charactersToIgnore: this.baseCharactersToIgnore.concat(this.soundtrackCharacters)});
      break;
      case 'voice-only':
      this.setState({audioMode: 'all', charactersToIgnore: this.baseCharactersToIgnore});
      //this.setState({audioMode: 'none', charactersToIgnore: this.baseCharactersToIgnore.concat(this.soundtrackCharacters)});
      break;
      /*case 'none':
      this.setState({audioMode: 'all', charactersToIgnore: this.baseCharactersToIgnore});
      break;*/
      default:
      break;
    }
    this.stageRef.current.doUpdate();
  }

  handleToggleInfo() {
    this.setState({welcomeVisible: !this.state.welcomeVisible});
  }

  handleTabSelected(tabId) {
    this.setState({selectedTab: tabId});
  }

  handlePickerScroll() {
    this.isInteracting = true;
    if (this.scrollTimeout) clearTimeout(this.scrollTimeout);
    this.scrollTimeout = setTimeout(() => {
      this.isInteracting = false;
      this.beatCounter = Math.max(1, this.beatCounter);
    }, 50);
  }

  render() {
    let tabContent = <div className="tab-content">
      <h1>Exposed</h1>
      <p className="credit"><span className="primary">By Sharon Daniel</span><br/>Design &amp; Programming by Erik Loyer</p>
      <ul className="tab-container">
        <li className={this.state.selectedTab === 'about' ? 'active' : ''} onClick={() => this.handleTabSelected('about')}>About</li>
        <li className={this.state.selectedTab === 'statement' ? 'active' : ''} onClick={() => this.handleTabSelected('statement')}>Statement</li>
        <li className={this.state.selectedTab === 'what-we-can-do' ? 'active' : ''} onClick={() => this.handleTabSelected('what-we-can-do')}>What We Can Do</li>
        <li className={this.state.selectedTab === 'credits' ? 'active' : ''} onClick={() => this.handleTabSelected('credits')}>Credits</li>
      </ul>
      {this.state.selectedTab === 'about' ?
        <div className="text-viewer">
          <p>The criminal punishment system in the United States confines over two million people in overcrowded, unsanitary, and unsafe environments where they cannot practice social distancing or use hand sanitizer and are regularly subjected to medical malpractice and neglect. EXPOSED documents the spread of COVID-19, over time, inside these prisons, jails, and detention centers, from the perspective of prisoners, detainees, and their families. Quotes, audio clips, and statistics collected from a comprehensive array of online publications and broadcasts, are assembled into an interactive timeline that, on each day, offers abundant testimony to the risk and trauma that prisoners experience under coronavirus quarantine. On July 8th alone, there are over 100 statements included in the interface — statements made by prisoners afflicted with the virus or enduring anxiety, distress, and severe hardship. Unfortunately, their words are all we have. Since the first reported coronavirus infection in the US, incarcerated people have been subjected to extreme forms of isolation — visits have been suspended, phone privileges restricted, and the use of solitary confinement expanded exponentially. Prisoners are stranded in quarantine without adequate food or medication — abandoned and unseen. EXPOSED reveals the overwhelming scope and scale of this humanitarian crisis. The monochrome, image-less, headline-styled interface, which allows viewers to step through thousands of prisoners’ statements, is designed to visualize their collective suffering, and signal that the injustices they endure are structural.</p>
        </div> : null}
      {this.state.selectedTab === 'statement' ?
        <div className="text-viewer">
          <p>The first confirmed case of COVID-19 infection among prisoners in the US was reported at the Federal Bureau of Prisons Metropolitan Detention Center in Brooklyn on March 21. The following day, the CDCR confirmed the first case within California’s vast prison system. As facilities across the US went on lockdown, prisoners presenting symptoms of coronavirus were put on ‘quarantine’ in ‘the hole,’ which is solitary confinement – a form of punishment widely regarded as psychological torture.</p>
          <p>15th century Venetians invented Quarantine as a protection against the plague.<sup>1</sup> Mid-20th century Americans invented a criminal punishment system, based on the model of quarantine, to protect white privilege and power against advances in civil rights and the end of Jim Crow laws that enforced racial segregation. New reactionary laws that essentially criminalized the lives of poor people and people of color were rapidly enacted, catalyzing a massive increase in the level of incarceration. As a result, prison yards, once reserved for those that society may have had a legitimate reason to fear, have filled to over 100% capacity with people that society finds annoying, fails to educate, and refuses to help. The disproportionately poor, Black, or Brown ‘offender’ is treated as a pathogen to be isolated and contained. Having nothing to do with justice or public safety, the quarantine of ‘undesirable others’ is the means and the end.</p>
          <p>Now, COVID-19, an actual pathogen, both exposes and intensifies the brutality of this quarantine punishment system; the overcrowding, the unsanitary and unsafe environments, medical malpractice and neglect, the abandonment of human life to the pathologies of power… In 1994, the Supreme Court warned, “having stripped [prisoners] of virtually every means of self-protection and foreclosed their access to outside aid, the government and its officials are not free to let the state of nature take its course.”<sup>2</sup> But our governments and officials have allowed the coronavirus to take its course in prisons, jails, and detention centers, and it has spread like wildfire.</p>
          <p>In late April 2020, a prisoner at Marion Correctional Institution in Ohio — which, at that time, was the largest coronavirus hotspot in the US — wrote the following:</p>
          <p><i>The social category of prisoner qualifies one as undeserving of a decent civilized life. Herein lies the cause of the profound spread of the virus throughout the institution: the collective sense of the undeservingness of prisoners. A vaccination would be nice. Proper P.P.E. would help. But the real cure for our woes is an affirmation of the inalienable entitlement to life for people in prisons and jails.<sup>3</sup></i></p>
          <p>The Eighth Amendment prohibits criminal punishments that are very harmful to prisoners and also rare or unprecedented - “cruel and unusual punishment.”<sup>4</sup> In 1976, the Supreme Court ruled that incarcerated people have a right to health care and deliberate indifference to an inmate’s health falls under the category of cruel and unusual punishment. The court has established that we must “draw the meaning” of the phrase, cruel and unusual, “from the evolving standards of decency that mark the progress of a maturing society.”  In May 2020, after inmates in Texas argued that their prison conditions during the pandemic amounted to cruel and unusual punishment, Justice Sonia Sotomayor wrote, "It has long been said that a society's worth can be judged by taking stock of its prisons. That is all the truer in this pandemic, where inmates everywhere have been rendered vulnerable and often powerless to protect themselves from harm..."<sup>5</sup></p>
          <p>The coronavirus is cruel and unusual. Sitting trapped in a prison cell waiting to be infected and die from COVID-19 is cruel and unusual punishment. Even before the pandemic, the quarantine punishment system stood as an indictment of society’s deteriorating standards of decency. It is immoral and inhumane to literally abandon incarcerated people to the ravages of COVID-19. Let them go. Now.</p>
          <hr/>
          <div class="footnotes">
            <sup>1</sup> <a target="_blank" rel="noopener noreferrer" href="https://thecrimereport.org/2020/04/08/public-health-justice-and-covid-19-a-tragedy-foretold/">https://thecrimereport.org/2020/04/08/public-health-justice-and-covid-19-a-tragedy-foretold/</a><br/>
            <sup>2</sup> <a target="_blank" rel="noopener noreferrer" href="https://supreme.justia.com/cases/federal/us/511/825/">https://supreme.justia.com/cases/federal/us/511/825/</a>
            <sup>3</sup> <a target="_blank" rel="noopener noreferrer" href="https://www.nytimes.com/2020/05/13/opinion/coronavirus-prison-outbreak.html">https://www.nytimes.com/2020/05/13/opinion/coronavirus-prison-outbreak.html</a><br/>
            <sup>4</sup> <a target="_blank" rel="noopener noreferrer" href="https://www.anchoragepress.com/columnists/imprisonment-during-the-pandemic-is-unconstitutional-cruel-and-unusual-punishment/article_1e30b9d8-a830-11ea-97aa-934e86c08673.html">https://www.anchoragepress.com/columnists/imprisonment-during-the-pandemic-is-unconstitutional-cruel-and-unusual-punishment/article_1e30b9d8-a830-11ea-97aa-934e86c08673.html</a><br/>
            <sup>5</sup> <a target="_blank" rel="noopener noreferrer" href="https://www.cnn.com/2020/05/14/politics/supreme-court-texas-inmates-coronavirus-protections/index.html">https://www.cnn.com/2020/05/14/politics/supreme-court-texas-inmates-coronavirus-protections/index.html</a><br/>
          </div>
        </div> : null}
      {this.state.selectedTab === 'what-we-can-do' ?
        <div className="text-viewer">
          <p>The pandemic that prisoners face is larger than COVID-19. The virus simply compounds the ills that wrongfully convicted, death row prisoner Tim Young described as, “the carceral continuum of slavery, incarceration, and systemic racism that began when English settlers landed upon the shores of Jamestown, Virginia in 1619.”</p>
          <p>We can cure these seemingly incurable and overlapping afflictions with transformative, restorative, and reparative justice.</p>
          <p>First, there must be a massive and permanent reduction in prison populations.</p>
          <p>Massive. Permanent. Decarceration. Now.</p>
          <p>In 41 states, <a target="_blank" rel="noopener noreferrer" href="https://en.wikipedia.org/wiki/Pardon#:~:text=The%20governors%20of%20most%20U.S.,offenses%20under%20state%20criminal%20law">your governor has the power to grant pardons, reprieves, commutations, and time-served releases for offenses under state criminal law.</a> Every state has a parole board. In 44 states the members of the parole board are appointed by the Governor. Your Governor is an elected official who works for you. Look your Governor up online. Call or write. Insist on a massive and permanent reduction in your state’s prison population.</p>
          <ul>
          <li>Say that your Governor’s refusal to save the lives of people behind bars, disproportionately Black and Brown people, suggests that they consider these people disposable.  They are not — they are human beings, and their lives matter.</li>
          <li>Say that people who have been convicted of violent crimes — not just nonviolent ones — must be considered for release.</li>
          <li>Say that there are alternatives to incarceration — that we can provide care and services first, and consider jail as a last resort. This will promote safety and fulfill the public’s need to see justice done.</li>
          </ul>
          <p>Second, there must be a multi-faceted effort to end the over-policing and criminalization of poor communities of color.</p>
          <p>Your local police and sheriff’s departments have the power to choose who, where and what to police and how policing will be conducted. Police Chiefs are generally appointed by, and answerable to elected officials. In most places, Sheriffs are elected officials. You can hold them accountable. And you can decide not to call the police unless your life is actually in danger.</p>
          <p>Your state’s Attorney General and local District Attorney have extraordinary discretion over who will be charged and what they will be charged with. A truly progressive DA can choose not to prosecute misdemeanors and minor drug charges. She can downgrade convictions from death to life without parole, and from life without parole to time served. She can decide not to request money bail. She can decide not to press charges just because an arrest takes place. You can support truly progressive DA and AG candidates and vote out regressive and retributive ones.</p>
          <p>Third, long before COVID-19 ravaged incarcerated populations and their local communities, government officials gutted social welfare programs and exponentially expanded the use of incarceration. These policy decisions have brutalized the poor and communities of color. Your city, county, and state legislators can change these policies and counteract the laws that criminalize addiction, mental illness, homelessness, poverty, and race. They can redistribute the public resources now allocated to enforcement and punishment, to provide health care, housing, education, and basic needs security.</p>
          <p>Decriminalization will increase public safety. If we think of an event or behavior as a crime, we can respond to it in a new way. We can change the way our society addresses violence; rather than simply reacting to violence after it occurs, we can work to change the social, behavioral, and environmental factors that cause violence. If we look at violence through the lens of public health, rather than policing and punishment, we can create systems that reduce violence and save the lives of both victims and offenders.</p>
          <p>Finally, <a target="_blank" rel="noopener noreferrer" href="http://criticalresistance.org/">prison abolition is necessary</a>. Incarceration, in any form, harms society more than it helps. Abolitionism allows us to think beyond prisons as a means of solving society’s problems, toward a future in which vital needs are met, and everyone can live safe and fulfilled lives.</p>
          <p>To reach this future, we will have to replace our quarantine-based criminal punishment system with a system of actual justice, one that is <a target="_blank" rel="noopener noreferrer" href="https://transformharm.org/">transformative</a>, <a target="_blank" rel="noopener noreferrer" href="http://restorativejustice.org/#sthash.50UYdAX2.dpbs">restorative</a>, and <a target="_blank" rel="noopener noreferrer" href="https://www.theatlantic.com/magazine/archive/2014/06/the-case-for-reparations/361631/">reparative</a>.</p>
        </div> : null}
      {this.state.selectedTab === 'credits' ?
        <div className="text-viewer">
          <p>By: Sharon Daniel<br/>Design and Programming by: Erik Loyer<br/>
          Research Assistants: Brian Myers, Abram Stern<br/>
          Research Interns: Norhan Abolail, Alyssa Brouwer, Nailea Castillo, Brandon Castro, Srijetta Islam, Joyce Lai, Sophia Parizadeh, Tabatha Ruiz, Shana Salem, Charlotte Schultz, Emily Schweitzer, Matt Sioson</p>
          <p>The quotes, audio clips and statistics included in EXPOSED are excerpts from a wide array of online publications and broadcasts. All excerpts are linked in the interface to the original source.</p>
          <p>Special thanks to <a target="_blank" rel="noopener noreferrer" href="https://timothyjamesyoung.com">Timothy James Young</a>, Wayne La Mar Palmer and Ty Zimmerschied for their contributions.</p>
          <h3>Data Sources</h3>
          <p><a target="_blank" rel="noopener noreferrer" href="https://law.ucla.edu/academics/centers/criminal-justice-program/ucla-covid-19-behind-bars-data-project">UCLA Law COVID-19 Behind Bars Data Project</a><br/>
          <a target="_blank" rel="noopener noreferrer" href="https://covidprisonproject.com/">The COVID Prison Project</a><br/>
          <a target="_blank" rel="noopener noreferrer" href="https://www.themarshallproject.org/2020/05/01/a-state-by-state-look-at-coronavirus-in-prisons?utm_source=The+Marshall+Project+Newsletter&utm_campaign=e6b3eea6da-EMAIL_CAMPAIGN_2020_05_02_12_23&utm_medium=email&utm_term=0_5e02cdad9d-e6b3eea6da-174549299">The Marshall Project State-by-State Look at Coronavirus in Prisons</a><br/>
          <a target="_blank" rel="noopener noreferrer" href="https://www.vera.org/ending-mass-incarceration/covid-19">Vera Institute of Justice</a></p>
          <p>Developed with <a target="_blank" rel="noopener noreferrer" href="http://step.works">Stepworks</a></p>
          <p>This project has been supported in part by the Arts Research Institute at the University of California, Santa Cruz</p>
        </div> : null}
    </div>
    let itemHeight;
    if (isMobile) {
      itemHeight = 90;
    } else {
      itemHeight = 150;
    }
    return (
      <div className="full-screen">
        { this.state.scoreLoaded ? <Attribution
            ref={this.attributionRef}
            stepwise={this.state.stepwise}
            onToggleAudio={this.handleToggleAudio.bind(this)}
            onToggleInfo={this.handleToggleInfo.bind(this)}
            audioMode={this.state.audioMode} /> : null }
        {<div className="stage-container">
          <Stage
            ref={this.stageRef}
            stepwise={this.state.stepwise}
            charactersToIgnore={this.state.charactersToIgnore}
            isPreviewing={true}
            visible={true}
          />
          { this.state.scoreLoaded ? <AudioPlayer ref={this.audioPlayerRef} onProgress={this.handleProgress} onEnded={this.handleAudioEnded.bind(this)} stepwise={this.state.stepwise}/> : null }
        </div>}
        {/* this.state.scoreLoaded ? <Timeline
          ref={this.timelineRef}
          onMonthSelected={this.handleMonthSelected.bind(this)}
          manifest={this.state.manifest}
          stepwise={this.state.stepwise}
          onProgress={this.handleProgress}/> : null */}
        { this.state.scoreLoaded ? <StatementPicker
          ref={this.statementPickerRef}
          height={itemHeight}
          itemHeight="30"
          interactable={!this.state.messageVisible && !this.state.welcomeVisible}
          onMonthSelected={this.handleMonthSelected.bind(this)}
          onProgress={this.handleProgress}
          onScroll={this.handlePickerScroll.bind(this)}
          random={this.state.random}
          stepwise={this.state.stepwise}
          manifest={this.state.manifest}/> : null }
        { this.state.messageVisible ? <div className="launch-message"><h1>{this.state.welcomeVisible ? '' : this.state.message}</h1></div> : null }
        { this.state.welcomeVisible ? <div className="welcome-message">
          {tabContent}
          { !this.state.scoreLoaded ? <div className="loading-message">Loading...</div> : <div className="launch-btns">
            <button className="toggle-off" onClick={this.toggleAutoplay.bind(this)}>{this.state.autoplay ? 'Autoplay enabled' : 'Autoplay disabled' }</button>
            <button onClick={this.closeWelcomeMessage.bind(this)}>Continue</button>
          </div> }
        </div> : null }
        { this.state.isLoading ? <div className="loading-popup">
          <p>Loading, one moment...</p>
        </div> : null }
      </div>
    )
  }
}

export default App;
