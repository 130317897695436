import React from 'react';
import ReactPlayer from 'react-player'
import ReactDOM from 'react-dom'
import TWEEN from '@tweenjs/tween.js';
import MediaCaption from './MediaCaption.js';

class VideoFeature extends React.Component {
  constructor(props) {
    super(props);
    this.animate = this.animate.bind(this);
    this.animateFade = this.animateFade.bind(this);
    this.video = null;
    this.audio = null;
    this.objectFit = 'cover';
    this.element = React.createRef();
    this.video = React.createRef();
    this.player = React.createRef();
    this.currentVideo = null;
    this.playing = false;
    this.isMuted = false;
    this.isAnimating = false;
    this.volume = 1;
    this.hasBlurred = true;
    this.isFading = false;
  }

  shouldComponentUpdate() {
    return false;
  }

  handleStoryUpdate() {
    this.forceUpdate();
  }

  applyState(state) {
    this.currentState = state;
    for (let property in state) {
      switch (property) {

        case 'media':
        /*let media = this.props.stepwise.score.getMedia(state[property]);
        this.currentVideo = media;
        this.playerURL = media.source;
        this.forceUpdate();*/
        //this.player.current.forceUpdate();
        this.setVideo(this.props.stepwise.score.getMedia(state[property]));
        break;

        case 'filter':
        this.element.current.style[property] = state[property];
        break;

        /*case 'volume':
        console.log('colume');
        var val = {t:this.volume};
        if (this.volumeTween) this.volumeTween.stop();
        this.volumeTween = new TWEEN.Tween(this.volume)
          .to({t:state.volume}, 1000)
          .onUpdate(() => {
            console.log(val.t);
            this.volume = val.t;
          })
          .onComplete(() => {
            this.isFading = false;
          })
          .start();
        this.isFading = true;
        requestAnimationFrame(this.animateFade);
        break;*/

        default:
        //this.video.current.style[property] = state[property];
        break;
      }
    }
    this.setTransition(state.transitionDuration);
  }

  animateFade(time) {
    if (this.isFading) requestAnimationFrame(this.animateFade);
    TWEEN.update(time);
  }

  doAction(action) {
    switch (action.command) {
      case 'enter':
      if (this.currentVideo) {
        this.playing = this.props.isPreviewing;
        /*if (this.currentVideo.source !== 'webcam' && this.currentVideo.source.indexOf('giphy') === -1) {
          this.props.onSolo(this.props.feature.parentCharacter.id);
        }*/
        this.isMuted = false;
        if (this.currentState.pauseOtherAudio) {
          //this.volume = 1;
          this.props.onSolo(this.props.feature.parentCharacter.id);
        } else {
          //this.volume = 0;
          //this.isMuted = true;
        }
        setTimeout(() => {
          this.forceUpdate();
        }, 250);
      }
      break;
      default:
      break;
    }
  }

  setTransition(secs) {
    //console.log('set transition '+secs);
    this.transition = secs;
    //this.video.current.style.transition = `scale ${secs}s, transform ${secs}s, background-size ${secs}s, opacity ${secs}s, filter ${secs}s`;
  }

  setVideo(media) {
    if (this.currentVideo !== media) {
      let video = this.video.current;
      let playerElement = ReactDOM.findDOMNode(this.player.current);
      if (media) {
        if (media.source === 'webcam') {
          if (navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia({ video: true })
              .then((stream) => {
                video.srcObject = stream;
                video.play();
              })
              .catch(function (err0r) {
                console.log("Something went wrong!");
              });
          }
          video.autoPlay = true;
          video.currentTime = 0;
          video.style.objectFit = this.objectFit;
          video.play();
          this.video.current.classList.remove('hidden');
          playerElement.classList.add('hidden');
        } else {
          this.playerURL = media.source;
          if (this.props.isPreviewing) this.playing = true;
          this.player.current.seekTo(0);
          this.isMuted = false;
          if (this.playerURL.indexOf('twitch.tv') !== -1 || this.playerURL.indexOf('streamable.com') !== -1) {
            var listener = window.addEventListener('blur', () => {
              if (document.activeElement.src) {
                if (document.activeElement.src.indexOf('twitch.tv') !== -1 || document.activeElement.src.indexOf('streamable.com') !== -1) {
                  this.hasBlurred = true;
                  setTimeout(() => {
                    window.focus();
                  }, 10);
                }
              }
              window.removeEventListener('blur', listener);
            });
            this.hasBlurred = false;
          } else {
            this.hasBlurred = true;
          }
          this.video.current.classList.add('hidden');
          playerElement.classList.remove('hidden');
        }
        /*this.isMuted = false;
        this.playing = true;
        this.forceUpdate();*/
        this.currentVideo = media;
        setTimeout(() => this.updateLayout(), 1);
      } else {
        this.playerURL = null;
        this.currentVideo = null;
      }
    }
    this.forceUpdate();
  }

  mute() {
    //console.log('mute '+this.props.feature.id);
    //this.playing = false;
    this.volume = 0;
    this.isMuted = true;
    this.forceUpdate();
  }

  togglePlayPause() {
    if (!this.isMuted) {
      this.playing = !this.playing;
      if (this.playing) this.playerIsMuted = false;
      this.forceUpdate();
    }
  }

  pause() {
    this.playing = false;
    this.forceUpdate();
  }

  animate(time) {
    if (this.isAnimating) requestAnimationFrame(this.animate);
    TWEEN.update(time);
  }

  animateScroll(element) {
    var val = {t:0};
    if (this.tween) this.tween.stop();
    this.tween = new TWEEN.Tween(val)
      .to({t:1}, 500)
      .easing(TWEEN.Easing.Quadratic.InOut)
      .onUpdate(() => {
        let outerWidth = parseFloat(this.element.current.offsetWidth);
        let outerHeight = parseFloat(this.element.current.offsetHeight);
        let outerAspectRatio = outerWidth / outerHeight;
        let mediaAspectRatio = 1;
        if (this.currentVideo) mediaAspectRatio = this.currentVideo.width / this.currentVideo.height;
        let h, w;
        if (this.currentState.fit === 'cover') {
          if (outerAspectRatio < mediaAspectRatio) {
            h = outerHeight;
            w = h * mediaAspectRatio;
            this.playerChild.style.width = w + 'px';
            this.playerChild.style.height = h + 'px';
            element.scrollLeft = (outerWidth - w) * -.5;
            element.scrollTop = 0;
          } else {
            w = outerWidth;
            h = w * (1 / mediaAspectRatio);
            this.playerChild.style.width = w + 'px';
            this.playerChild.style.height = h + 'px';
            this.playerChild.style.height = h + 'px';
            this.playerChild.style.marginTop = 0;
            this.playerChild.style.marginLeft = 0;
            element.scrollLeft = 0;
            element.scrollTop = (outerHeight - h) * -.5;
          }
        } else if (this.currentState.fit === 'contain') {
          if (outerAspectRatio > mediaAspectRatio) {
            h = outerHeight;
            w = h * mediaAspectRatio;
            this.playerChild.style.width = w + 'px';
            this.playerChild.style.height = h + 'px';
            this.playerChild.style.marginLeft = (outerWidth - w) * .5 + 'px';
            this.playerChild.style.marginTop = 0;
            element.scrollLeft = 0
            element.scrollTop = 0;
          } else {
            w = outerWidth;
            h = w * (1 / mediaAspectRatio);
            this.playerChild.style.width = w + 'px';
            this.playerChild.style.height = h + 'px';
            this.playerChild.style.marginTop = (outerHeight - h) * .5 + 'px';
            this.playerChild.style.marginLeft = 0;
            element.scrollLeft = 0;
            element.scrollTop = 0;
          }
        }
      })
      .onComplete(() => {
        this.isAnimating = false;
      })
      .start();
    this.isAnimating = true;
    requestAnimationFrame(this.animate);
  }

  updateLayout(unit) {
    if (unit) this.unit = unit;
    if (this.currentVideo) {
      if (this.currentVideo.source !== 'webcam') {
        let playerElement = ReactDOM.findDOMNode(this.player.current);
        if (playerElement) {
          this.playerChild = playerElement.firstChild;
          if (this.playerChild) {
            this.animateScroll(playerElement);
          }
        }
      }
    }
  }

  handleStep(step) {
    var video;
    if (step) {
      switch (step.command) {
        case 'narrate':
        case 'speak':
        case 'think':
        case 'sing':
        var extension = window.getFilenameExtension(step.content);
        switch (extension) {

          case 'mp4':
          case 'mov':
          this.clearMedia();
          this.video = document.createElement('video');
          this.video.src = step.content;
          this.video.autoPlay = true;
          this.element.current.appendChild(this.video);
          this.video.currentTime = 0;
          this.video.style.objectFit = this.objectFit;
          this.video.play();
          setTimeout(() => { this.props.character.registerMedia(this.video); }, 10);
          break;

          case 'gif':
          case 'png':
          case 'jpg':
          case 'jpeg':
          this.video = null;
          break;

          case 'mp3':
          case 'ogg':
          this.clearMedia();
          this.audio = document.createElement('audio');
          this.audio.src = step.content;
          this.audio.autoPlay = true;
          this.element.current.appendChild(this.audio);
          this.audio.currentTime = 0;
          this.audio.play();
          setTimeout(() => { this.props.character.registerMedia(this.audio); }, 10);
          break;

          default:
          break;
        }
        break;

        case 'pause':
        if (this.video) this.video.pause();
        if (this.audio) this.audio.pause();
        break;

        case 'play':
        if (this.video) this.video.play();
        if (this.audio) this.audio.play();
        break;

        case 'seekTo':
        var time = parseFloat(step.content);
        if (this.video && !isNaN(video.duration)) {
          if (step.content === 'random') {
            this.video.currentTime = Math.random() * this.video.duration;
          } else if (!isNaN(time)) {
            this.video.currentTime = time;
          }
        }
        /*if (audio) {
          if (step.content === 'random') {
            audio.currentTime = Math.random() * audio.duration;
          } else if (!isNaN(time)) {
            audio.currentTime = time;
          }
        }*/
        break;

        case 'setLoop':
        if (this.video) this.video.loop = step.content === 'true';
        if (this.audio) this.audio.loop = step.content === 'true';
        break;

        case 'setFit':
        this.objectFit = step.content;
        if (this.video) this.video.style.objectFit = step.content;
        break;

        case 'setVolume':
        if (this.video) this.video.volume = parseFloat(step.content);
        if (this.audio) this.audio.volume = parseFloat(step.content);
        break;

        case 'clearVideo':
        this.clearVideo();
        break;

        default:
        break;
      }
    }
  }

  handleVideoReady() {
    this.updateLayout();
  }

  render() {
    let className = 'panel-video';
    if (this.playerURL) {
      if (this.playerURL.indexOf('twitch.tv') === -1) {
        className += ' disable-interaction';
      } else if (this.hasBlurred) {
        className += ' disable-interaction';
      }
    } else {
      className += ' disable-interaction';
    }
    return (
      <div className={className} ref={this.element}>
        <video className="webcam hidden" ref={this.video}/>
        <ReactPlayer
          className="hidden"
          ref={this.player}
          url={this.playerURL}
          config={{
            youtube: {
                playerVars: {
                  showinfo: 0,
                  rel: 0,
                  modestbranding: 1,
                  iv_load_policy: 3,
                  disablekb: 1
                }
            }
          }}
          playing={this.playing}
          volume={this.isMuted ? 0 : this.currentState ? this.currentState.volume : 1}
          style={{overflow:"scroll"}}
          width="100%" height="100%"
          onReady={this.handleVideoReady.bind(this)}
          loop={this.currentState ? this.currentState.loop : true}
        />
        <MediaCaption media={this.currentVideo}/>
      </div>
    )
  }
}

export default VideoFeature;
