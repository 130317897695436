import React from 'react';
import { isMobile } from  'react-device-detect';

class Attribution extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      attribution: '',
      source: '',
      progress: 0
    }
    this.element = React.createRef();
    this.audioMessage = React.createRef();
    this.audioStatusTimeout = null;
  }

  handleStoryUpdate() {
  }

  handleStepwiseEvent(type, obj) {
    switch (type) {

      case 'scoreLoaded':
      this.attributionChar = this.props.stepwise.score.getCharacter('attribution');
      break;

      case 'action':
      switch (obj.command) {

        case 'speak':
        if (obj.targetCharacter.id === 'attribution') {
          this.handleAttributionAction(obj);
        } else if (obj.targetCharacter.id === 'source') {
          this.handleSourceAction(obj);
        }
        break;

        default:
        break;
      }
      break;

      case 'step':
      this.updateAttributionForCurrentStatement();
      break;

      default:
      break;
    }
  }

  handleAttributionAction(obj) {
    let temp = obj.content.split(',');
    this.name = temp[0].trim();
    if (this.name !== '') {
      if (this.name.toLowerCase() !== 'anonymous') {
        this.name += ' -';
      }
      this.name += ' ';
    }
    this.role = temp[1].trim();
    this.prison = temp[2].trim();
    let state = temp[3].trim();
    if (this.prison !== '') {
      if (state !== '') {
        this.prison += ', ' + state;
      }
      if (!isMobile) {
        this.setState({attribution: <div className="person"><span className="name">{this.name}</span>{this.role}<br/><span className="prison">{this.prison}</span></div>});
      }
    } else {
      if (!isMobile) {
        this.setState({attribution: <div className="person"><span className="name">{this.name}</span>{this.role}</div>});
      }
    }
  }

  handleSourceAction(obj) {
    let temp = obj.content.split(',');
    let source = temp[0];
    let link = temp[1];
    let date = temp[2];
    //this.setState({source: <div className="source"><a href={link} target="_blank" rel="noopener noreferrer">{source}</a><br/>{new Date(date).toLocaleDateString(undefined, {month: 'long', day: 'numeric', year: 'numeric'})}</div>});
    if (!isMobile) {
      if (link.trim() !== '') {
        this.setState({source: <div className="source"><a href={link} target="_blank" rel="noopener noreferrer">{source}</a><br/>{date}</div>});
      } else {
        this.setState({source: <div className="source">{source}<br/>{date}</div>});
      }
    } else {
      let attribution;
      if (this.prison.indexOf(',') !== -1) {
        attribution = <div className="person"><a href={link} target="_blank" rel="noopener noreferrer"><span className="name">{this.name}</span>{this.role}</a><br/><span className="prison">{this.prison}</span></div>;
      } else {
        attribution = <div className="person"><a href={link} target="_blank" rel="noopener noreferrer"><span className="name">{this.name}</span>{this.role}</a></div>;
      }
      this.setState({
        attribution: attribution,
        source: null
      });
    }
  }

  updateAttributionForCurrentStatement() {
    let index = this.getStartingIndexOfCurrentStatement();
    let step = this.props.stepwise.score.currentScene.currentSequence.steps[index];
    step.actions.forEach(action => {
      if (action.targetCharacter.id === 'attribution') {
        this.handleAttributionAction(action);
      } else if (action.targetCharacter.id === 'source') {
        this.handleSourceAction(action);
      }
    })
  }

  getStartingIndexOfCurrentStatement() {
    let sequence = this.props.stepwise.score.currentScene.currentSequence;
    let isFirstStepOfStatement = false;
    let index = sequence.stepIndex;
    let step;
    do {
      step = sequence.steps[index];
      isFirstStepOfStatement = step.containsActionForCharacter('speak', this.attributionChar);
      index--;
    } while (!isFirstStepOfStatement && index > -1);
    index++;
    return index;
  }

  handleProgress(progress) {
    this.setState({progress: progress});
  }

  handleAudioClick() {
    this.props.onToggleAudio();
    if (this.audioMessage.current) {
      this.audioMessage.current.classList.add('show');
      if (this.audioStatusTimeout) clearTimeout(this.audioStatusTimeout);
      setTimeout(() => {
        this.audioMessage.current.classList.remove('show');
        this.audioStatusTimeout = null;
      }, 2000);
    }
  }

  render() {
    let audioIconUrl;
    if (this.props.audioMode === 'voice-only') {
      audioIconUrl = 'images/audio-some.png';
    } else {
      audioIconUrl = 'images/audio-all.png';
    }

    return (
      <div className="header">
        <div className="header-wing">
          <div className="wing-controls">
            <button onClick={this.handleAudioClick.bind(this)}><img src={audioIconUrl} alt="Audio control speaker icon"/></button>
          </div>
        </div>
        <div className="attribution" ref={this.element}>
          <div className="attribution-box-bg" style={{"width":(this.state.progress * 100) + "%"}}/>
          {this.state.attribution}
          {this.state.source}
        </div>
        <div className="header-wing">
          <div className="wing-controls">
            <button onClick={this.props.onToggleInfo}><img src="images/info.png" alt="Ellipsis icon"/></button>
          </div>
        </div>
      </div>
    )
  }
}

export default Attribution;
