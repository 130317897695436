import React from 'react';
import TWEEN from '@tweenjs/tween.js';
import { isMobile } from  'react-device-detect';

class PickerColumn extends React.Component {
  constructor(props) {
    super(props);
    this.columnRef = React.createRef();
    this.isAutoScrolling = false;
    this.animateScroll = this.animateScroll.bind(this);
    this.selectionOffset = isMobile ? 1 : 2;
    this.targetIndex = -1;
  }

  handleScroll(evt) {
    if (!this.isAutoScrolling) { // if this is a manual scroll event (not tweened)
      this.props.onScroll();
      if (this.tween) this.tween.stop();
      let index;
      if (this.targetIndex !== -1) {
        index = this.targetIndex;
        this.targetIndex = -1;
      } else {
        index = Math.round(evt.currentTarget.scrollTop / this.props.itemHeight);
      }
      if (this.scrollTimeout) clearTimeout(this.scrollTimeout);
      this.scrollTimeout = setTimeout(() => {
        this.snapToIndex(index, true);
      }, 50);
    } else {
      this.targetIndex = -1;
    }
  }

  snapToIndex(index, instantaneous = false, sendEvent = true) {
    this.targetIndex = index;
    if (!instantaneous) {
      if (this.tween) this.tween.stop();
      this.isAutoScrolling = true;
      this.tween = new TWEEN.Tween(this.columnRef.current)
        .to({scrollTop:index * this.props.itemHeight}, 300)
        .easing(TWEEN.Easing.Quadratic.Out)
        .onComplete(() => {
          // ensure that isAutoScrolling is still true by the time the end-of-scroll event above is triggered, so we
          // can tell the different between a tweened scroll (which shouldn't trigger an event) and a user scroll
          // (which should)
          setTimeout(() => this.isAutoScrolling = false, 1000);
        })
        .start();
      requestAnimationFrame(this.animateScroll);
    } else {
      this.columnRef.current.scrollTop = index * this.props.itemHeight;
    }
    if (sendEvent) this.props.onChange(this.props.name, index);
  }

  animateScroll(time) {
    if (this.isAutoScrolling) requestAnimationFrame(this.animateScroll);
    TWEEN.update(time);
  }

  renderItems() {
    const {options, itemHeight} = this.props;
    let optionsProxy = options.concat();
    optionsProxy.unshift(' ');
    if (!isMobile) optionsProxy.unshift(' ');
    optionsProxy.push(' ');
    if (!isMobile) optionsProxy.push(' ');
    return optionsProxy.map((option, index) => {
      const style = {
        height: itemHeight + 'px',
        lineHeight: itemHeight + 'px'
      };
      const className = `picker-item${index - this.selectionOffset === this.props.index ? ' picker-item-selected' : ''}`;
      return (
        <div
          key={index}
          className={className}
          style={style}
          onClick={() => this.snapToIndex(Math.min(index - this.selectionOffset, this.props.options.length - 1), true)}>{option}</div>
      );
    })
  }

  render() {
    let className = 'picker-column ' + this.props.name;
    return <div ref={this.columnRef} className={className} onScroll={this.handleScroll.bind(this)}>
      <div className="picker-scroller">
        {this.renderItems()}
      </div>
    </div>
  }
}

export default PickerColumn;
