import React from 'react';
import MediaCaption from './MediaCaption.js';

class ImageFeature extends React.Component {
  constructor(props) {
    super(props);
    this.image = React.createRef();
    //this.captionRef = React.createRef();
    //this.titleRef = React.createRef();
    //this.creditRef = React.createRef();
    this.transition = .5;
    this.stepsSinceImageSet = 0;
    this.currentImage = null;
  }

  shouldComponentUpdate() {
    return false;
  }

  handleStoryUpdate() {
    this.forceUpdate();
  }

  applyState(state) {
    this.currentState = state;
    for (let property in state) {
      switch (property) {

        case 'media':
        this.setImage(this.props.stepwise.score.getMedia(state[property]));
        break;

        default:
        this.image.current.style[property] = state[property];
        break;
      }
    }
    this.setTransition(state.transitionDuration);
  }

  doAction(action) {
    switch (action.command) {
      case 'show-image':
      this.setImage(this.props.stepwise.score.getMedia(action.content));
      break;
      default:
      break;
    }
  }

  mute() {

  }

  togglePlayPause() {

  }

  pause() {

  }

  updateLayout() {

  }

  setImage(media) {
    if (this.currentImage !== media) {
      if (media) {
        this.image.current.classList.remove('disable-interaction');
        this.stepsSinceImageSet = 0;
        this.image.current.style.backgroundImage = 'url('+media.source+')';
        /*if (media.attribution) {
          this.titleRef.current.textContent = media.name;
          if (!media.attributionURL) {
            this.creditRef.current.textContent = media.attribution;
          } else {
            this.creditRef.current.innerHTML = '<a href="' + media.attributionURL + '" target="_blank">' + media.attribution + '</a>';
          }
          this.captionRef.current.classList.remove('hidden');
          this.captionRef.current.classList.remove('fade-out');
        } else {
          this.captionRef.current.classList.add('hidden');
        }*/
        this.currentImage = media;
      } else {
        this.stepsSinceImageSet = 0;
        this.image.current.style.backgroundImage = 'none';
        //this.captionRef.current.classList.add('hidden');
        this.currentImage = null;
      }
    } else {
      this.image.current.classList.add('disable-interaction');
      this.stepsSinceImageSet++;
      //this.captionRef.current.classList.add('fade-out');
    }
    this.forceUpdate();
  }

  setTransition(secs) {
    //console.log('set transition '+secs);
    this.transition = secs;
    this.image.current.style.transition = `scale ${secs}s, transform ${secs}s, background-image ${secs}s, background-size ${secs}s, background-position ${secs}s, opacity ${secs}s, filter ${secs}s`;
  }

  /*handleStep(step) {
    if (step) {
      var css = this.image.current.style;
      switch (step.command) {
        case 'narrate':
        case 'speak':
        case 'think':
        case 'sing':
        var extension = window.getFilenameExtension(step.content);
        switch (extension) {
          case 'gif':
          case 'png':
          case 'jpg':
          case 'jpeg':
          css.backgroundImage = 'url('+step.content+')';
          break;
          default:
          break;
        }
        break;
        case 'clearImage':
        css.backgroundImage = 'none';
        break;
        default:
        break;
      }
    }
  }*/

  render() {
    return (
      <div className="panel-image disable-interaction" ref={this.image}>
        <MediaCaption media={this.currentImage}/>
      </div>
    )
  }
}

export default ImageFeature;
