import React from 'react';

class AudioPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      audioMedia: null
    }
    this.audioPlayer = React.createRef();
  }

  handleStoryUpdate() {
  }

  handleStepwiseEvent(type, obj) {
    switch (type) {

      case 'state':
      switch (obj.type) {

        case 'audio':
        if (obj.targetCharacter.id === 'interview-audio') {
          let media = this.props.stepwise.score.getMedia(obj.media);
          let okToSetAudio = true;
          if (this.state.audioMedia && media) {
            if (this.state.audioMedia.source === media.source) {
              okToSetAudio = false;
            }
          }
          if (okToSetAudio) {
            this.setState({audioMedia: media});
          }
        }
        break;

        default:
        break;

      }
      break;

      default:
      break;
    }
  }

  handleTimeUpdate() {
    let progress = this.audioPlayer.current.currentTime / this.audioPlayer.current.duration;
    this.props.onProgress(progress);
  }

  render() {
    return (
      <div className="audio-player">
        {this.state.audioMedia ? <audio
          ref={this.audioPlayer}
          onTimeUpdate={this.handleTimeUpdate.bind(this)}
          onEnded={this.props.onEnded}
          src={this.state.audioMedia.source}
          controls autoPlay/> : null}
      </div>
    )
  }
}

export default AudioPlayer;
